.header-status {
    position: relative;
    display: flex;
    align-items: center;
    height: 70px;
    width: 100%;
    background-color: rgba(5,7,39,1);
	background-image: image-set(
		url("/public/Mask_Group_12_fr.png") 1x,
		url("/public/Mask_Group_12_fr@2x.png") 2x);
    color: rgba(255,255,255,1);
	margin: 0;
	padding: 0;
  }

  .header-env {
	height: 100%;
	display: flex;
	margin-top: auto;
	margin-bottom: auto;
	align-items: center;
	align-content: center;
	margin-right: 2%;
  }

  .header-user {
    position: absolute;
	top: 0;
	left: 0;
    display: flex;
    align-items: center;
    height: 80.5px;
    width: 100%;
    background-color: rgba(255,255,255,1);
    color: rgba(77,79,92,1);
	margin: auto;
	border-bottom: 1px solid #D8D8D8;
	border-radius: 29px 29px 0px 0px;
	z-index: 5;
	-webkit-transition: width 1s, height 1s; /* Safari */
    transition: width 1s, height 1s;
  }

  .header-user.basic {
	height: 13.79898%;
  }

  .header-user.advanced {
	height: 11.3287%;
  }

  .header-user.minimal {
	height: 39.3204%;
  }

  .header-user.history {
	height: 12.3664%;
  }

  .header-user.broker-home {
	height: 11.3287%;
  }

  .header-user.profile-setup {
	height: 11.3287%;
  }

  .header-left {
	position: relative;
	height: inherit;
	float: right;
	margin-left: auto;
	display: flex;
	margin-right: 5%;
	justify-content: flex-end;
	height: 40%;
	width: 34%;
  }

  .header-user.minimal .header-left{
	height: 35%;
  }

  .header-notifications {
	width: auto;
	height: 100%;
	margin-top: auto;
	margin-bottom: auto;
	display: block;
	padding-bottom: 5%;
  }

  .header-notifications-alerts {
	width: 60%;
	height: 50%;
	border-radius: 50%;
	background-color: #FFC200;
	font: normal normal 500 calc(11*var(--responsiveFont))/calc(16*var(--responsiveFont)) NotoSans;
	color: #FFFFFF;
	position: relative;
	left: 50%;
	bottom: 102%;
	text-align: center;
  }

  .header-notifications-dropdown {
	height: 750%;
    width: 100%;
    position: absolute;
    left: -21.5%;
    top: 130%;
    background: #FFFFFF;
    border-radius: 13px;
    border: 1px solid #9a9a9a;
	display: none;
  }

  .header-notifications:hover .header-notifications-dropdown{
	display: block;
  }

  .header-notifications-dropdown-title{
	width: 95%;
	height: 17%;
	padding-left: 5%;
	padding-top: 2%;
	position: relative;
	text-align: left;
	font: normal normal bold calc(23*var(--responsiveFont)) NotoSans;
	letter-spacing: 0px;
	color: black;
	display: flex;
	align-items: center;
  }

  .header-notifications-dropdown-options{
	height: 11%;
    width: 93%;
    padding-left: 7%;
    position: relative;
	font: normal normal bold calc(13*var(--responsiveFont)) NotoSans;
	display: flex;
  }

  .header-notifications-dropdown-options div {
	width: auto;
	height: 100%;
	display: flex;
	align-items: center;
	margin-right: 10%;
	padding-right: 1%;
	padding-left: 1%;
	font-weight: 600;
	cursor: pointer;
  }

  .header-notifications-dropdown-data{
	width: 90%;
    padding-left: 5%;
    padding-top: 4%;
    height: 61%;
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
	overflow: overlay;
	white-space: nowrap;
	cursor: pointer;
  }

.header-notifications-dropdown-data::-webkit-scrollbar {
	display: none;
  }

  .header-notifications-dropdown-data-entry {
	height: 20%;
	width: 100%;
	font: normal normal 600 calc(15*var(--responsiveFont)) NotoSans;
	color: #393a44;
    text-decoration: underline;
	text-overflow: ellipsis;
	overflow: hidden;
  }

  .header-notifications-dropdown-data-entry-broker {
	height: 20%;
	width: 100%;
	font: normal normal 600 calc(14*var(--responsiveFont)) NotoSans;
	color: #393a44;
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
  }

  .notifAction {
	font-weight: 800;
	color: black;
  }

  .header-rating {
	width: 32.56%;
	height: 100%;
	display: flex;
	margin-top: auto;
	margin-bottom: auto;
	align-items: center;
	align-content: center;
	margin-right: 2%;
  }

  .header-rating-profile {
	height: 100%;
	display: flex;
	margin-top: auto;
	margin-bottom: auto;
	align-items: center;
	align-content: center;
	margin-right: 2%;
  }

  .header-profile {
	margin-top: auto;
	margin-bottom: auto;
	display: flex;
	height: 80%;
	cursor: default;
	padding-top: 1%;
	padding-bottom: 10%;
  }

  .header-profile:hover .dropdown {
	display: block;
  }

  .header-profile:hover .Path_26_f	{
	transform: matrix(-1,0,0,-1,0,0);
  }

  .profile-name {
	margin-top: auto;
	margin-bottom: auto;
	text-align: left;
	font: normal normal 500 calc(14*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
	letter-spacing: 0px;
	color: #4D4F5C;
	white-space: nowrap;
  }

  .profile-arrow {
	margin-top: auto;
	margin-bottom: auto;
	margin-left: 3%;
	margin-right: 3%;
	display: flex;
	height: 100%;
	width: auto;
	align-items: center;
	justify-content: center;
  }

  .profile-icon {
	margin-top: auto;
	margin-bottom: auto;
	margin-left: 3%;
	margin-right: 3%;
	width: auto;
	height: 100%;
	display: flex;
  }

  .dropdown {
	display: none;
	flex-direction: column;
	position: absolute;
	margin-top: 10%;
	justify-content: left;
	justify-items: center;
	z-index: 2;
	width: 70%;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border: 1px solid #CBCBCB;
	border-radius: 9px;
	right: -12%;
	white-space: nowrap;
  }

  .dropdown:last-child {
	padding-bottom: 2.5%;
  }

  .dropdown-content {
	display: block;
	text-decoration: none;
	margin-top: 5%;
	margin-left: 5%;
	margin-right: 1%;
	text-align: left;
	font: normal normal normal calc(12*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
	letter-spacing: 0px;
	color: #4D4F5C;
	cursor: pointer;
  }

  .dropdown-content:hover {
	font: normal normal 600 calc(12*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
	color: #0D1265;
  }

  .navigation {
    text-align: left;
	margin-left: auto;
	margin-right: 1.2rem;
	margin-top: auto;
	margin-bottom: auto;
  }

  .navigation a {
    margin: 1rem 0 0 1.2rem;
    color: rgba(178,178,178,1);
    text-decoration: none;
	font-family: NotoSans;
    font-weight: 600;
	font-size: 16px;
	line-height: 13px;
	letter-spacing: 0px;
  }

  .navigation .active {
    color: rgba(255,255,255,1);
  }

  .navigation a:hover {
    text-decoration: underline;
  }

  #Path_2783_fq {
		fill: rgba(5,7,39,1);
	}
	.Path_2783_fq {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		overflow: visible;
		position: absolute;
		width: 1458px;
		height: 96px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_2784_q {
		border: 1px solid #FFFFFF;
		opacity: 1;
		height: 18px;
		width: 0px;
	}
	.Mask_Group_12_fr {
		opacity: 0.06;
		position:absolute;
		width: 100%;
		height: 78px;
		left: 0px;
		top: 0px;
		overflow:visible;
	}
	#Path_373_ft {
		fill: rgba(255,255,255,1);
	}
	.Path_373_ft {
		overflow: visible;
		position: absolute;
		width: 14.154px;
		height: 16.956px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_374_fu {
		fill: rgba(255,255,255,1);
	}
	.Path_374_fu {
		overflow: visible;
		position: absolute;
		width: 10.674px;
		height: 11.102px;
		left: 13.246px;
		top: 6.081px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_375_fv {
		fill: rgba(255,255,255,1);
	}
	.Path_375_fv {
		overflow: visible;
		position: absolute;
		width: 5.904px;
		height: 15.089px;
		left: 23.744px;
		top: 1.867px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_376_fw {
		fill: rgba(255,255,255,1);
	}
	.Path_376_fw {
		overflow: visible;
		position: absolute;
		width: 10.975px;
		height: 16.778px;
		left: 30px;
		top: 0.177px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_377_fx {
		fill: rgba(255,255,255,1);
	}
	.Path_377_fx {
		overflow: visible;
		position: absolute;
		width: 10.346px;
		height: 10.951px;
		left: 41.227px;
		top: 6.182px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_378_fy {
		fill: rgba(255,255,255,1);
	}
	.Path_378_fy {
		overflow: visible;
		position: absolute;
		width: 10.975px;
		height: 10.875px;
		left: 51.625px;
		top: 6.081px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_379_fz {
		fill: rgba(255,255,255,1);
	}
	.Path_379_fz {
		overflow: visible;
		position: absolute;
		width: 5.904px;
		height: 15.089px;
		left: 62.447px;
		top: 1.867px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_380_f {
		fill: rgba(255,255,255,1);
	}
	.Path_380_f {
		overflow: visible;
		position: absolute;
		width: 3.33px;
		height: 14.534px;
		left: 68.529px;
		top: 2.422px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_381_f {
		fill: rgba(255,255,255,1);
	}
	.Path_381_f {
		overflow: visible;
		position: absolute;
		width: 9.537px;
		height: 10.951px;
		left: 72.313px;
		top: 6.182px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_382_f {
		fill: rgba(255,255,255,1);
	}
	.Path_382_f {
		overflow: visible;
		position: absolute;
		width: 3.582px;
		height: 16.652px;
		left: 86.67px;
		top: 0.304px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_383_f {
		fill: rgba(255,255,255,1);
	}
	.Path_383_f {
		overflow: visible;
		position: absolute;
		width: 10.975px;
		height: 10.875px;
		left: 90.656px;
		top: 6.081px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_384_f {
		fill: rgba(255,255,255,1);
	}
	.Path_384_f {
		overflow: visible;
		position: absolute;
		width: 10.697px;
		height: 16.981px;
		left: 102.389px;
		top: 0.151px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_385_f {
		fill: rgba(255,255,255,1);
	}
	.Path_385_f {
		overflow: visible;
		position: absolute;
		width: 3.33px;
		height: 14.534px;
		left: 113.818px;
		top: 2.422px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_386_f {
		fill: rgba(255,255,255,1);
	}
	.Path_386_f {
		overflow: visible;
		position: absolute;
		width: 9.537px;
		height: 10.951px;
		left: 118.109px;
		top: 6.182px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_387_f {
		fill: rgba(255,255,255,1);
	}
	.Path_387_f {
		overflow: visible;
		position: absolute;
		width: 9.336px;
		height: 10.951px;
		left: 128.1px;
		top: 6.182px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_388_f {
		fill: rgba(255,255,255,1);
	}
	.Path_388_f {
		overflow: visible;
		position: absolute;
		width: 5.904px;
		height: 15.089px;
		left: 136.729px;
		top: 1.867px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_389_f {
		fill: rgba(255,255,255,1);
	}
	.Path_389_f {
		overflow: visible;
		position: absolute;
		width: 3.33px;
		height: 14.534px;
		left: 143.818px;
		top: 2.422px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_390_ga {
		fill: rgba(255,255,255,1);
	}
	.Path_390_ga {
		overflow: visible;
		position: absolute;
		width: 11.152px;
		height: 10.951px;
		left: 147.629px;
		top: 6.182px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_391_gb {
		fill: rgba(255,255,255,1);
	}
	.Path_391_gb {
		overflow: visible;
		position: absolute;
		width: 10.977px;
		height: 10.875px;
		left: 159.488px;
		top: 6.081px;
		transform: matrix(1,0,0,1,0,0);
	}
  #Group_1168_fs {
		position: absolute;
		width: 170.465px;
		height: 17.183px;
		left: 8.3%;
		top: 38.5%;
		overflow: visible;
		fill: transparent;
	}

	#Group_72 {
		position: relative;
		width: auto;
		height: 29.765%;
		left: 3.15%;
	}

	#Group_72_img {
		width: 100%;
		height: 100%;
	}

	#Group_9 {
		width: 21px;
		height: 23px;
		position: relative;
		margin: auto;
	}

	.header-left #Group_9 {
		width: auto;
		height: 90%;
		top: 16%;
	}

	#Group_11 {
		width: 24px;
		height: 23px;
		position: relative;
		margin: auto;
	}

	.header-left #Group_11 {
		width: auto;
		height: 90%;
	}

	.Path_315_xl {
		overflow: visible;
		position: relative;
		width: auto;
		height: 85%;
		margin: auto;
		transform: matrix(1,0,0,1,0,0);
	}

	#Path_315_xl {
		fill: rgba(255,255,255,1);
		stroke: rgba(255,196,0,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}

	.header-left #Path_315_xl {
		height: 85%;
		width: auto;
	}

	.Path_26_f {
		overflow: visible;
		position: relative;
		width: 11px;
		height: 7px;
		transform: matrix(1,0,0,1,0,0);
		transition: 0.25s linear;
	}

	.header-left .Path_26_f {
		overflow: visible;
		position: relative;
		display: block;
		width: auto;
		height: 30%;
		transform: matrix(1,0,0,1,0,0);
		transition: 0.25s linear;

	}

	#Path_26_f {
		fill: rgba(164,175,183,1);
	}

	#n_9f0f55ae0f8e499334416c4e87a9_e {
		width: auto;
		height: 110%;
		overflow: visible;
	}

	#Path_4965 {
		height: 0px;
		border: 1px solid #CBCBCB;
		margin: 4% 3%;
	}

	.Divider{
		width: 1px;
		height: 100%;
		background: #EBEBF2 0% 0% no-repeat padding-box;
		opacity: 1;
		margin-top: auto;
		margin-bottom: auto;
		margin-right: 2%;
		margin-left: 2%;
		overflow: visible;
	}

    .langButton {
        background-color: transparent;
        outline: none;
        cursor: pointer;
        color: rgba(255,255,255,1);
        text-align: left;
		font-family: NotoSans;
		font-size: calc(16*var(--responsiveFont));
		line-height: calc(24*var(--responsiveFont));
		font-weight: 400;
		letter-spacing: 0px;
		border: transparent;
		margin: auto .1rem ;
    }

    .langButtonActive {
        font-weight: 700;
		background-color: transparent;
        outline: none;
        cursor: pointer;
        color: rgba(255,255,255,1);
        text-align: left;
		font-family: NotoSans;
		font-size: calc(16*var(--responsiveFont));
		line-height: calc(24*var(--responsiveFont));
		letter-spacing: 0px;
		border: transparent;
		margin: auto .1rem ;
    }

	.langDropdownActive {
		text-align: left;
		text-decoration: underline;
		font: normal normal bold calc(12*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
		letter-spacing: 0px;
	}

	.mobile-header{
		width: 100vw;
		height: 6vh;
		position: sticky;
		top: 0;
		right: 0;
		margin: 0;
		padding: 0;
		background-color: rgba(5,7,39,1);
		background-image: image-set(
			url("/public/Mask_Group_12_fr.png") 1x,
			url("/public/Mask_Group_12_fr@2x.png") 2x);
		color: rgba(255,255,255,1);
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		z-index: 10;
	}

	/* Landscape orientation */
	@media screen and (orientation: landscape) {
		.mobile-header{
			height: 13vh;
		}
	}

    .mobile-header-logo{
		width: 42.26666%;
		height: 100%;
		margin-left: 5%;
	}

	.mobile-header-logo #Group_1168_fs {
		position: relative;
		left: 0;
		top: 30%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

    .mobile-header-hamburger-menu{
		width: 6.2%;
		height: 100%;
		margin-right: 5%;
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
	}

	.hamburger-menu-icon-header {
		width: 80%;
		height: 80%;
		fill: #FFFFFF;
	}

    .mobile-header-hamburger-menu-content{
		width: 100%;
		height: 361.248%;
		background-image: image-set(
			url("/public/Group_949.png") 1x,
			url("/public/Group_949@2x.png") 2x);
		background-position: right;
		background-size: auto 90%;
		background-repeat: no-repeat;
		background-color: white;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		overflow: hidden;
		transition: height 1s, opacity 1s;
		top: 0;
		opacity: 100;
		z-index: 10;
	}

    .mobile-header-hamburger-menu-content-about-us{
		font: normal normal normal 14pt NotoSans;
		color: #000000;
		margin-left: 8%;
		text-decoration: none;
	}

    .mobile-header-hamburger-menu-content-contact-us{
		font: normal normal normal 14pt NotoSans;
		color: #000000;
		margin-left: 8%;
	}

    .mobile-header-hamburger-menu-content-language-switch{
		font: normal normal normal 14pt NotoSans;
		color: #000000;
		margin-left: 8%;
	}

	#nav-icon3{
		width: 100%;
		height: 50%;
		position: relative;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: .5s ease-in-out;
		-moz-transition: .5s ease-in-out;
		-o-transition: .5s ease-in-out;
		transition: .5s ease-in-out;
		cursor: pointer;
	  }

	  #nav-icon3 span{
		display: block;
		position: absolute;
		height: 15%;
		width: 100%;
		background: #FFFFFF;
		border-radius: 9px;
		opacity: 1;
		left: 0;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: .25s ease-in-out;
		-moz-transition: .25s ease-in-out;
		-o-transition: .25s ease-in-out;
		transition: .25s ease-in-out;
	  }

	  /* Icon 3 */

	  #nav-icon3 span:nth-child(1) {
		top: 0;
	  }

	  #nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3) {
		top: 40%;
	  }

	  #nav-icon3 span:nth-child(4) {
		top: 80%;
	  }

	  #nav-icon3.open span:nth-child(1) {
		top: 40%;
		width: 0%;
		left: 50%;
	  }

	  #nav-icon3.open span:nth-child(2) {
		-webkit-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-o-transform: rotate(45deg);
		transform: rotate(45deg);
	  }

	  #nav-icon3.open span:nth-child(3) {
		-webkit-transform: rotate(-45deg);
		-moz-transform: rotate(-45deg);
		-o-transform: rotate(-45deg);
		transform: rotate(-45deg);
	  }

	  #nav-icon3.open span:nth-child(4) {
		top: 40%;
		width: 0%;
		left: 50%;
	  }
