.IOI-basic-window {
    width: 70.68%;
    height: 44.199%;
    left: 26.008%;
    top: 31.233%;
    position: relative;
}

.IOI-window-transitions {
    -webkit-transition: width 1s, height 1s, top 1s, left 1s; /* Safari */
    transition: width 1s, height 1s, top 1s, left 1s;
}

.IOI-window-top {
    width: 100%;
    height: 24.285%;
    border-radius: 29px 29px 0px 0px;
    border-top: 1px solid #E3E3E3;
    border-left: 1px solid #E3E3E3;
    border-right: 1px solid #E3E3E3;
    text-align: center;
    font: normal normal normal calc(22*var(--responsiveFont))/calc(20*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #FCFCFC;
    opacity: 1;
    display: flex;
    transition: height 1.1s 0s;
    -webkit-transition: height 1.1s 0s;
    flex-direction: column;
    justify-content: center;
    background-color: #0D1265;
	background-image: image-set(
		url("/public/Mask_Group_10.png") 1x,
		url("/public/Mask_Group_10@2x.png") 2x);
}

.IOI-window-top.advanced {
    height: 16.195%;
    transition: height 0.8s 0ms;
    -webkit-transition: height 0.8s 0ms;
}

.IOI-window-body-selling {
    height: 35.25%;
    width: 100%;
    border-bottom: solid 4px #BC3C3C;
    display: flex;
    border-left: 1px solid #E3E3E3;
    border-right: 1px solid #E3E3E3;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    transition: height 1.1s 0s;
    -webkit-transition: height 1.1s 0s;
}

.IOI-window-body-selling.advanced {
    height: 23.523%;
    transition: height 0.8s 0ms;
    -webkit-transition: height 0.8s 0ms;
}

.IOI-window-body-buying.advanced {
    height: 23.523%;
    transition: height 0.8s 0ms;
    -webkit-transition: height 0.8s 0ms;
}

.IOI-window-body-buying {
    height: 35.25%;
    width: 100%;
    border-bottom: solid 4px #0EAA3B;
    display: flex;
    border-left: 1px solid #E3E3E3;
    border-right: 1px solid #E3E3E3;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    transition: height 1.1s 0s;
    -webkit-transition: height 1.1s 0s;
}

.buy-sell-toggle-selling {
    margin-left: 7.3%;
    width: 10.481%;
    height: 35%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #BC3C3C;
    border-radius: 29px;
    opacity: 1;
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.buy-sell-toggle-buying {
    margin-left: 7.3%;
    width: 10.481%;
    height: 35%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #0EAA3B;
    border-radius: 29px;
    opacity: 1;
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.sell-selling {
    text-align: center;
    font: normal normal bold calc(14*var(--responsiveFont))/calc(21*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #FCFCFC;
    background-color: transparent;
    opacity: 1;
    z-index: 2;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10%;
    width: 50%;
}

.sell-buying {
    text-align: center;
    font: normal normal bold calc(14*var(--responsiveFont))/calc(21*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #5C5C5C;
    background-color: transparent;
    opacity: 1;
    z-index: 2;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10%;
    width: 50%;
}

.buy-selling {
    text-align: center;
    font: normal normal 500 calc(14*var(--responsiveFont))/calc(21*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #5C5C5C;
    opacity: 1;
    z-index: 2;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10%;
    width: 50%;
}

.buy-buying {
    text-align: center;
    font: normal normal 500 calc(14*var(--responsiveFont))/calc(21*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #FCFCFC;
    opacity: 1;
    z-index: 2;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10%;
    width: 50%;
}

.toggle-background-selling {
    position: absolute;
    width: 55%;
    height: 100%;
    left: 0%; /*45% to switch*/
    background: #BC3C3C 0% 0% no-repeat padding-box;
    border-radius: 29px;
    z-index: 1;
    opacity: 1;
}

.toggle-background-buying {
    position: absolute;
    width: 55%;
    height: 100%;
    left: 45%; /*0% to switch*/
    background: #0EAA3B 0% 0% no-repeat padding-box;
    border-radius: 29px;
    z-index: 1;
    opacity: 1;
}

.quantity-selling {
    height: 52.47%;
    width: 14.5%;
    margin-left: 5.6%;
    margin-top: auto;
    margin-bottom: auto;
    border-bottom: 2px solid #BC3C3C;
}

.quantity-buying {
    height: 52.47%;
    width: 14.5%;
    margin-left: 5.6%;
    margin-top: auto;
    margin-bottom: auto;
    border-bottom: 2px solid #06580D;
}

.quantity-title {
    text-align: center;
    font: normal normal 500 calc(14*var(--responsiveFont))/calc(23*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #2F2F2F;
    opacity: 1;
}

.quantity-field-selling {
    text-align: center;
    font: normal normal bold calc(20*var(--responsiveFont))/calc(23*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #BC3C3C;
    opacity: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    outline: none;
}

.quantity-field-buying {
    text-align: center;
    font: normal normal bold calc(20*var(--responsiveFont))/calc(23*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0EAA3B;
    opacity: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    outline: none;
}

.usd {
    height: 52.47%;
    width: 14.5%;
    min-width: 14.5%;
    margin-left: 1.33%;
    margin-top: auto;
    margin-bottom: auto;
    border-bottom: 2px solid #E9E9F0;
}

.usd-title {
    text-align: center;
    font: normal normal 500 calc(14*var(--responsiveFont))/calc(23*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #2F2F2F;
    opacity: 1;
}

.usd-field {
    text-align: center;
    font: normal normal bold calc(20*var(--responsiveFont))/calc(23*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    outline: none;
}

.jpy {
    height: 52.47%;
    min-width: 14.5%;
    width: 14.5%;
    margin-left: 1.33%;
    margin-top: auto;
    margin-bottom: auto;
    border-bottom: 2px solid #E9E9F0;
}

.jpy-title {
    text-align: center;
    font: normal normal 500 calc(14*var(--responsiveFont))/calc(23*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #2F2F2F;
    opacity: 1;
}

.jpy-field {
    text-align: center;
    font: normal normal bold calc(20*var(--responsiveFont))/calc(23*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    outline: none;
}

.transaction-type {
    margin-left: 7%;
    height: 52.5%;
    padding-bottom: 4px;
    width: 16.05%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
}

.transaction-type-title {
    text-align: center;
    font: normal normal 500 calc(14*var(--responsiveFont))/calc(23*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #2F2F2F;
    opacity: 1;
    height: 40%;
    top: -3px;
    position: relative;
}

.transaction-type-dropdown {
    text-align: center;
    font: normal normal 600 calc(12*var(--responsiveFont))/calc(30*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
    opacity: 1;
    border: 1px solid #0D1265;
    border-radius: 17px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    height: 54.17%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: nowrap;
    cursor: pointer;
}

.transaction-type-dropdown .Path_26_f {
    width: auto;
    height: 25%;
}

.transaction-type:hover .Path_26_f	{
    transform: matrix(-1,0,0,-1,0,0);
}

.transaction-type:hover .transaction-type-options {
    display: flex;
    z-index: 7;
}

.transaction-type-options:hover {
    display: flex;
    z-index: 7;
}

.transaction-type-options span {
    margin-left: 8%;
    margin-bottom: 1.55%;
    cursor: pointer;
}

.transaction-type-options span:first-child {
    margin-top: 6%;
}

.transaction-type-options span:last-child {
    margin-bottom: 6%;
}

.transaction-type-options {
    position: relative;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    text-align: left;
    font: normal normal normal calc(12*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #4D4F5C;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #707070;
    border-radius: 9px;
    opacity: 1;
}

.transaction-type-options span:hover {
    font: normal normal 600 calc(12*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    color: #0D1265;
}

.IOI-window-advanced-selling {
    width: 100%;
    height: 39.127%;
    border-radius: 0px 0px 29px 29px;
    border-left: 1px solid #E3E3E3;
    border-right: 1px solid #E3E3E3;
    border-bottom: 1px solid #E3E3E3;
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
}

.IOI-window-advanced-buying {
    width: 100%;
    height: 39.127%;
    border-radius: 0px 0px 29px 29px;
    border-left: 1px solid #E3E3E3;
    border-right: 1px solid #E3E3E3;
    border-bottom: 1px solid #E3E3E3;
    background-image: image-set(
		url("/public/Group_1001_zt.png") 1x,
		url("/public/Group_1001_zt@2x.png") 2x);
    background-position: bottom;
    background-size: 100% 80%;
    background-repeat: no-repeat;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
}

.IOI-window-advanced-selling::before {
    content: "";
    border-radius: 0px 0px 29px 29px;
    position: absolute;
    width: 100%;
    height: 39.127%;
    background-image: image-set(
		url("/public/Group_1001_zt.png") 1x,
		url("/public/Group_1001_zt@2x.png") 2x);
    background-position: bottom;
    background-size: 100% 80%;
    background-repeat: no-repeat;
    background-color: transparent;
    filter: brightness(0) saturate(100%) invert(30%) sepia(62%) saturate(921%) hue-rotate(318deg) brightness(97%) contrast(100%);
}

.plus-icon {
    display: flex;
    width: auto;
    height: 10%;
    text-align: center;
    position: absolute;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 21%;
    animation: x-to-plus-rotation 1s 1 linear;
    transform:rotate(0deg);
}

.plus-icon.advanced {
    animation: plus-to-x-rotation 1s 1 linear;
    transform: rotate(45deg);
    height: 6%;
}

@keyframes plus-to-x-rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(45deg);
    }
}

@keyframes x-to-plus-rotation {
    from {
      transform: rotate(45deg);
    }
    to {
      transform: rotate(0deg);
    }
}

.plus-icon:hover #Group_20_bh{
    height: 100%;
    width: auto;
    left: 0;
}

#Group_20_bh {
    height: 83.333%;
    width: auto;
    filter: brightness(0) saturate(100%) invert(6%) sepia(87%) saturate(5483%) hue-rotate(243deg) brightness(82%) contrast(104%);
}

.credibility-filter-value {
    display: flex;
    width: 100%;
}

.credibility-filter-value #Group_11 {
    width: 20%;
    height: auto;
}

.credibility-filter-value .Path_315_xl {
    width: 20%;
    height: auto;
}

.advanced-options-menu {
    height: 37.45%;
    width: 52%;
    margin-left: 26.3%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgba(242, 242, 242, 1);
    border-radius: 9px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    z-index: 3;
    transition: height 0s 0s;
}

.advanced-options-menu.advanced {
    height: 100%;
    transition: height 500ms 500ms;
}

.advanced-options-menu div {
    text-align: center;
    font: normal normal 500 calc(14*var(--responsiveFont))/calc(15*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #6F6F6F;
    opacity: 1;
    background: #F2F2F2 0% 0% no-repeat padding-box;
}

.advanced-menu-divider{
    width: 1px;
    background-color: rgba(222, 222, 222, 1);
    height: 100%;
}

.price-conditions {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 30%;
    border-radius: 9px 0px 0px 9px;
}

.advanced-options-menu div:hover {
    color: #FFFFFF;
    background: #0D1265 0% 0% no-repeat padding-box;
}

.advanced-options-menu div.selected {
    color: #FFFFFF;
    background: #0D1265 0% 0% no-repeat padding-box;
}

.advertising-preferences {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 40%;
    border-right: 1px solid rgba(222, 222, 222, 1);
    border-left: 1px solid rgba(222, 222, 222, 1);
}

.path-preferences {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 30%;
    border-radius: 0px 9px 9px 0px;
}

.AdvertButtons {
    height: 11.4%;
    display: flex;
    width: 40%;
    justify-content: space-evenly;
    position: absolute;
    left: 41.101%;
    top: 85.315%;
}

.cancel-button {
    width: 31.6%;
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 3px solid #C64B8C;
    border-radius: 14px;
    opacity: 1;
    text-align: center;
    font: normal normal 600 calc(20*var(--responsiveFont))/calc(30*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #1D1D1D;
    margin-right: 1%;
}

.cancel-button:disabled {
    opacity: 0.5;
}

.cancel-button:not(:disabled):hover {
    background: #C64B8C;
    color: #FFFFFF;
}

.pause-button {
    width: 31.6%;
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 3px solid #FFC400;
    border-radius: 14px;
    opacity: 1;
    text-align: center;
    font: normal normal 600 calc(20 * var(--responsiveFont))/calc(30* var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #1D1D1D;
    margin-right: 1%;
}

.pause-button:disabled {
    opacity: 0.5;
}

.pause-button:not(:disabled):hover {
    background-color: #FFC400;
    color: #FFFFFF;
}

.send-button {
    width: 31.6%;
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 3px solid #4169E1;
    border-radius: 14px;
    opacity: 1;
    text-align: center;
    font: normal normal 600 calc(20*var(--responsiveFont))/calc(30*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #1D1D1D;
}

.send-button:disabled {
    opacity: 0.5;
}

.send-button:not(:disabled):hover {
    background-color: #4169E1;
    color: #FFFFFF;
}

.IOI-summary-button {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #0D1265;
    border-radius: 20px;
    opacity: 1;
    height: 7.5%;
    width: 12.1%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    top: 73.93%;
    left: 6.89%;
}

.IOI-summary-button:hover {
    background-color: #0D1265;
}

.IOI-summary-button:hover #Group_38_a {
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(73deg) brightness(102%) contrast(102%);
}

.IOI-summary-button:hover .IOI-summary-button-text {
    color: #FFFFFF;
}

#Group_38_a {
    width: 10.13%;
    height: 43.8%;
    opacity: 1;
}

.IOI-summary-button-text {
    text-align: center;
    font: normal normal 600 calc(14*var(--responsiveFont))/calc(30*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
    opacity: 1;
}

.IOI-status {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #E3E3E3;
    border-radius: 14px;
    opacity: 1;
    width: 19.5%;
    height: 11.4%;
    position: absolute;
    left: 3.21%;
    top: 85.31%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.IOI-status-text {
    margin-top: 7%;
    text-align: center;
    font: normal normal normal calc(14*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #000000;
}

.IOI-status-string-{
    text-align: center;
    font: normal normal bold calc(14*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #4169E1;
}

.IOI-status-timestamp {
    text-align: center;
    font: normal normal 600 calc(22*var(--responsiveFont))/calc(30*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 7%;
}

.IOI-status-hours-indicator {
    height: 22.2%;
    background-color: #C64B8C;
    width: 6%;
    border-radius: 50%;
    position: absolute;
    top: -12%;
    right: -3.4%;
    border: 1px solid #E3E3E3;
}

.IOI-status-hours-indicator.advanced {
    height: 18.23%;
}

.IOI-status-hours-indicator-menu {
    position: absolute;
    width: 105%;
    min-height: 55%;
    border: 2px solid #E3E3E3;
    border-radius: 9px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: -76%;
    right: -52.5%;
    z-index: 10;
    text-align: center;
    font: normal normal 700 calc(12*var(--responsiveFont))/calc(15*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
    overflow: overlay;
}

.IOI-status-hours-indicator:hover + .IOI-status-hours-indicator-menu {
    display: flex;
}

.IOI-status-hours-indicator-menu:hover {
    display: flex;
}

.IOI-listings-window {
    width: 20%;
    height: 100%;
    max-height: calc(45.4% + 1px);
    border-radius: 14px 14px 14px 14px;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
    border: 1px solid #E3E3E3;
    position: absolute;
    left: 3.238%;
    top: 20.194%;
}

.IOI-listings-window::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
    background-clip:padding-box;
    position: absolute;
  }
  .IOI-listings-window::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    background-clip:border-box;
    border-radius: 8px;
    padding-left: 10px;
    position: absolute;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 1);
  }


.userIOI {
    text-align: center;
    width: 100%;
    height: 15.38%;
    position: relative;
    display: flex;
    align-items: center;
    border-top: 1px solid #E3E3E3;
    border-bottom: 1px solid #E3E3E3;
    border-right: 1px solid #E3E3E3;
    right: 0;
    z-index: 2;
    background-color: #FFFFFF;
    overflow: visible;
}

.emptyIOI{
    text-align: center;
    width: 100%;
    height: 15.38%;
    position: relative;
    display: flex;
    align-items: center;
    border-top: 1px solid #E3E3E3;
    border-bottom: 1px solid #E3E3E3;
    border-right: 1px solid #E3E3E3;
    right: 0;
    z-index: 2;
    background-color: #FFFFFF;
    overflow: visible;
}

.IOI-listings-window > div:first-child {
    border-radius: 14px 14px 0px 0px;
    border-top: none;
}

.IOI-listings-window > div:last-child {
    border-radius: 0px 0px 14px 14px;
}

.userIOI-status-SENT {
    width: 3.7%;
    height: 100%;
    background: #4169E1 0% 0% no-repeat padding-box;
    border: 1px solid #4169E1;
}

.userIOI-status-LIVE {
    width: 3.7%;
    height: 100%;
    background: #4169E1 0% 0% no-repeat padding-box;
    border: 1px solid #4169E1;
}

.userIOI-status-UNSENT {
    width: 3.7%;
    height: 100%;
    background: #0D1265 0% 0% no-repeat padding-box;
    border: 1px solid #0D1265;
}

.userIOI-status-ACCEPT\/DECLINE\? {
    width: 3.7%;
    height: 100%;
    background: #0D1265 0% 0% no-repeat padding-box;
    border: 1px solid #0D1265;
}

.userIOI-status-LOCATED {
    width: 3.7%;
    height: 100%;
    background: #0D1265 0% 0% no-repeat padding-box;
    border: 1px solid #0D1265;
}

.userIOI-status-COMPLETED {
    width: 3.7%;
    height: 100%;
    background: #000000 0% 0% no-repeat padding-box;
    border: 1px solid #000000;
}

.userIOI-status-PAUSED {
    width: 3.7%;
    height: 100%;
    background: #FFC400 0% 0% no-repeat padding-box;
    border: 1px solid #FFC400;
}

.userIOI-status-DEAD {
    width: 3.7%;
    height: 100%;
    background: #C64B8C 0% 0% no-repeat padding-box;
    border: 1px solid #C64B8C;
}

.IOI-status-string-SENT {
    text-align: center;
    font: normal normal bold calc(14*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #4169E1;
}

.IOI-status-string-LIVE {
    text-align: center;
    font: normal normal bold calc(14*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #4169E1;
}

.IOI-status-string-UNSENT {
    text-align: center;
    font: normal normal bold calc(14*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
}

.IOI-status-string-ACCEPT\/DECLINE\? {
    text-align: center;
    font: normal normal bold calc(14*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
}

.IOI-status-string-LOCATED {
    text-align: center;
    font: normal normal bold calc(14*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
}

.IOI-status-string-COMPLETED {
    text-align: center;
    font: normal normal bold calc(14*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #000000;
}

.IOI-status-string-PAUSED {
    text-align: center;
    font: normal normal bold calc(14*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #FFC400;
}

.IOI-status-string-DEAD {
    text-align: center;
    font: normal normal bold calc(14*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #C64B8C;
}

.userIOI-security {
    width: 80%;
    height: 100%;
    text-align: left;
    font: normal normal normal calc(13*var(--responsiveFont))/calc(10*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.userIOI-security-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-y: clip;
    overflow: hidden;
    line-height: normal;
    width: 90%;
}

.userIOI-security-text-bold {
    font-weight: bold;
}

.userIOI-security-text-direction {
    color: #FFFFFF;
    font: normal normal bold calc(13*var(--responsiveFont))/calc(10*var(--responsiveFont)) NotoSans;
    width: auto; 
    aspect-ratio: 1/1;
    height: 70%;
    border-radius: 50%;
    display: flex;
    align-items: center; 
    justify-content: center;
}

.userIOI-security-text-direction-BUY {
    background: #0EAA3B;
  }

  .userIOI-security-text-direction-SELL {
    background: #BC3C3C;
  }

.userIOI-security-selected {
    width: 208.5px;
    height: 100%;
    text-align: left;
    font: normal normal bold calc(13*var(--responsiveFont))/calc(10*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#Group_20_a{
    width: auto;
    height: 40%;
}

#Group_20 {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 2.6px;
}

.emptyIOI #Group_20 {
    width: auto;
    height: 38%;
    right: 0;
}

.IOI-plus-icon{
    width: 16px;
    height: 16px;
    right: 0;
    display: flex;
    position: absolute;
    margin-right: 8px;
    font: normal normal bold calc(18*var(--responsiveFont))/calc(10*var(--responsiveFont)) NotoSans;
    color: #B1B1B1;
    align-items: center;
}

.emptyIOI .IOI-plus-icon{
    width: auto;
    height: 100%;
    margin-right: 9%;
}

#quantity-field-input {
    text-align: center;
    width: 90%;
    font: normal normal bold calc(20*var(--responsiveFont))/calc(23*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: inherit;
    background: transparent;
    border: none;
    outline: none;
    text-overflow: ellipsis;
}

#usd-field-input {
    text-align: center;
    font: inherit;
    letter-spacing: inherit;
    color: inherit;
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
    text-overflow: ellipsis;
}

#jpy-field-input {
    text-align: center;
    font: inherit;
    letter-spacing: inherit;
    color: inherit;
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
    text-overflow: ellipsis;
}

.ioi-home-security-name {
    padding: 0 1rem;
    overflow: hidden;
    line-height: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
}