.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 0.5rem;
}

.form > h4 {
  margin: 1rem 0;
}

.form-field {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form.horizontal > .form-field {
  flex-direction: row;
}

.form-field > label {
  font-weight: 600;
  font-size: 0.9em;
}

.form.horizontal > .form-field > label {
  width: 10rem;
}

.form-field input[type=text],
.form-field input[type=password],
.form-field input[type=number],
.form-field input[type=email] {
  border-radius: 1.5rem;
  padding: 0.5rem 1rem;
  width: 20rem;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #E3E2E6;
  font-size: 1em;
}

.form-field input[type=text].error,
.form-field input[type=text].error:focus,
.form-field input[type=password].error,
.form-field input[type=password].error:focus,
.form-field input[type=number].error,
.form-field input[type=number].error:focus,
.form-field input[type=email].error,
.form-field input[type=email].error:focus {
  border-color: red;
  color: red;
}

.form-field input[type=text]:focus,
.form-field input[type=password]:focus,
.form-field input[type=number]:focus,
.form-field input[type=email]:focus {
  outline: none;
  border: 1px solid #0D1265;
}

.form-field input[type=text]::placeholder,
.form-field input[type=password]::placeholder,
.form-field input[type=number]::placeholder,
.form-field input[type=email]::placeholder,
.form-field input[type=text]::-webkit-input-placeholder,
.form-field input[type=password]::-webkit-input-placeholder,
.form-field input[type=number]::-webkit-input-placeholder,
.form-field input[type=text]::-ms-input-placeholder,
.form-field input[type=email]::-ms-input-placeholder,
.form-field input[type=password]::-ms-input-placeholder,
.form-field input[type=number]::-ms-input-placeholder,
.form-field input[type=email]::-ms-input-placeholder {
  color: #96969F;
}

.form-field input:disabled {
  background: transparent;
}

.form-field input[type=text]:disabled,
.form-field input[type=password]:disabled,
.form-field input[type=number]:disabled,
.form-field input[type=email]:disabled {
  background: #fafafa;
  color: #96969F;
}

.form-field > input:has(input:disabled) {
  background: #fafafa;
}

p.error {
  margin: 0;
  color: red;
  font-size: 0.8em;
}

p.success {
  margin: 0;
  color: green;
  font-size: 0.8em;
}

.form-field > .credibility-filter-value {
  width: 8rem;
}

.form-field > .duration-menu,
.form-field > .transaction-type-menu {
  width: 20rem;
  position: relative;
  padding-bottom: 0.5rem;
}

.form-field > .duration-menu > .duration-option-dropdown,
.form-field > .transaction-type-menu > .transaction-type-option-dropdown {
  margin: auto;
  justify-content: space-between;
  padding: 0 1rem;
  width: 100%;
}

.form-field > .duration-menu > .duration-option-dropdown .Path_26_f,
.form-field > .transaction-type-menu > .transaction-type-option-dropdown .Path_26_f {
  width: 1rem;
}

.form-field .transaction-type-option-options {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin: 0;
}

.form-field .transaction-type-option-options span {
  margin-left: 1rem;
  margin-bottom: 0.5rem;
}

.form-field .transaction-type-option-options span:first-child {
  margin-top: 1rem;
}

.form-field .transaction-type-option-options span:last-child {
  margin-bottom: 1rem;
}

.form-field .minimum-q-slider {
  height: auto;
  width: 20rem;
  margin: 0;
  position: relative;
}

.form-field .minimum-q-slider-input {
  height: 0.5rem;
}

.form-field .minimum-q-slider-input-hover-value {
  background: white;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
}

.form-field .quantity-decay-toggle-container {
  width: 2rem;
  height: 1rem;
  margin: 0;
  cursor: pointer;
}

.form-field .quantity-decay-toggle-container.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.form-field > .rank-options {
  margin: 0;
  width: auto;
  height: auto;
  align-items: start;
  gap: 0.5rem;
}

.form-field > .rank-options > .rank-option {
  margin: 0;
  width: auto;
  height: auto;
  padding-left: 1.5rem;
}

.form-field .radioCheck {
  width: 1rem;
  height: 1rem;
}

.form-field .broker-option-drag-drop-container {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.form-field .broker-rank {
  width: auto;
  margin: 0;
}

.form-field .broker-option-drag-drop {
  height: 2rem;
  gap: 8px;
  padding: 0 8px;
}

.form-field .draggable-icon-container {
  margin: 0;
  width: 1rem;
}

.form-field .broker-option-text {
  width: 5rem;
  height: auto;
}

.form-field .broker-option-drag-drop .Path_26_f {
  height: 0.7rem;
  margin: 0;
}

.form-field .broker-option-dropdown-menu {
  top: 100%;
  left: 0;
  height: 6rem;
  overflow: auto;
}