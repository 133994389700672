.tabs {
  display: flex;
  flex-direction: row;
  font-size: small;
  gap: 2rem;
}

.tab {
  cursor: pointer;
}

.tab:hover {
  font-weight: bold;
  border-bottom: 2px solid #0D1265;
}

.tab.active {
  font-weight: bold;
  border-bottom: 2px solid #0D1265;
}