div.in-collab-div {
    position: relative;
    left: 8.3%;
    height:260px;
    width:170px;
  }
p.in-collab-with {
    text-align: center;
    margin-block-end: .0em;
}

p.press-release {
    text-align: center;
    margin-block-start: .0em;
    margin-block-end: .1em;
}

img.JPX-logo {
    height: 200px;
}

  