.App {
  text-align: center;
}

#root {
  white-space: pre-line;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: NotoSans;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

/*USED FONTS*/
@font-face {
font-family: "NotoSans";
src: local("NotoSansBlack"),
 url("./fonts/Noto_Sans/NotoSans-Black.ttf") format("truetype");
font-weight: 900;
font-style: normal;
}

@font-face {
font-family: "NotoSans";
src: local("NotoSansBold"),
 url("./fonts/Noto_Sans/NotoSans-Bold.ttf") format("truetype");
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: "NotoSans";
src: local("NotoSansExtraBold"),
 url("./fonts/Noto_Sans/NotoSans-ExtraBold.ttf") format("truetype");
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: "NotoSans";
src: local("NotoSansExtraLight"),
 url("./fonts/Noto_Sans/NotoSans-ExtraLight.ttf") format("truetype");
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: "NotoSans";
src: local("NotoSansLight"),
 url("./fonts/Noto_Sans/NotoSans-Light.ttf") format("truetype");
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: "NotoSans";
src: local("NotoSansMedium"),
 url("./fonts/Noto_Sans/NotoSans-Medium.ttf") format("truetype");
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: "NotoSans";
src: local("NotoSansRegular"),
 url("./fonts/Noto_Sans/NotoSans-Regular.ttf") format("truetype");
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: "NotoSans";
src: local("NotoSansSemiBold"),
 url("./fonts/Noto_Sans/NotoSans-SemiBold.ttf") format("truetype");
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: "NotoSans";
src: local("NotoSansThin"),
 url("./fonts/Noto_Sans/NotoSans-Thin.ttf") format("truetype");
font-weight: 100;
font-style: normal;
}

/*NOTO SANS JP*/
@font-face {
font-family: "NotoSans";
src: local("NotoSansJPBlack"),
 url("./fonts/Noto_Sans_JP/NotoSansJP-Black.otf") format("opentype");
font-weight: 900;
font-style: normal;
unicode-range: 	U+A5, U+4E00-9FFF, U+30??, U+FF00-FF9F;
}

@font-face {
font-family: "NotoSans";
src: local("NotoSansJPBold"),
 url("./fonts/Noto_Sans_JP/NotoSansJP-Bold.otf") format("opentype");
font-weight: 700;
font-style: normal;
unicode-range: 	U+A5, U+4E00-9FFF, U+30??, U+FF00-FF9F;
}

@font-face {
font-family: "NotoSans";
src: local("NotoSansJPBold"),
  url("./fonts/Noto_Sans_JP/NotoSansJP-Bold.otf") format("opentype");
font-weight: 800;
font-style: normal;
unicode-range: 	U+A5, U+4E00-9FFF, U+30??, U+FF00-FF9F;
}

@font-face {
font-family: "NotoSans";
src: local("NotoSansJPLight"),
 url("./fonts/Noto_Sans_JP/NotoSansJP-Light.otf") format("opentype");
font-weight: 300;
font-style: normal;
unicode-range: 	U+A5, U+4E00-9FFF, U+30??, U+FF00-FF9F;
}

@font-face {
font-family: "NotoSans";
src: local("NotoSansJPMedium"),
 url("./fonts/Noto_Sans_JP/NotoSansJP-Medium.otf") format("opentype");
font-weight: 500;
font-style: normal;
unicode-range: 	U+A5, U+4E00-9FFF, U+30??, U+FF00-FF9F;
}

@font-face {
font-family: "NotoSans";
src: local("NotoSansJPMedium"),
  url("./fonts/Noto_Sans_JP/NotoSansJP-Medium.otf") format("opentype");
font-weight: 600;
font-style: normal;
unicode-range: 	U+A5, U+4E00-9FFF, U+30??, U+FF00-FF9F;
}

@font-face {
font-family: "NotoSans";
src: local("NotoSansJPRegular"),
 url("./fonts/Noto_Sans_JP/NotoSansJP-Regular.otf") format("opentype");
font-weight: 400;
font-style: normal;
unicode-range: 	U+A5, U+4E00-9FFF, U+30??, U+FF00-FF9F;
}

@font-face {
font-family: "NotoSans";
src: local("NotoSansJPThin"),
 url("./fonts/Noto_Sans_JP/NotoSansJP-Thin.otf") format("opentype");
font-weight: 100;
font-style: normal;
unicode-range: 	U+A5, U+4E00-9FFF, U+30??, U+FF00-FF9F;
}

@font-face {
font-family: "NotoSans";
src: local("NotoSansJPThin"),
  url("./fonts/Noto_Sans_JP/NotoSansJP-Thin.otf") format("opentype");
font-weight: 200;
font-style: normal;
unicode-range: 	U+A5, U+4E00-9FFF, U+30??, U+FF00-FF9F;
}

@font-face {
  font-family: "Forum";
  src: url("./fonts/Forum/Forum-Regular.ttf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

.two-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 0 0.5rem;
}