.return-to-menu-button {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #0D1265;
    width: 11.684%;
    height: 6.718%;
    border-radius: 29px;
    text-align: center;
    font: normal normal 600 calc(14*var(--responsiveFont))/calc(30*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
    top: 85.878%;
    left: 6.683%;
    position: absolute;
}

.return-to-menu-button:hover {
    background-color: #0D1265;
    color: #FFFFFF;
}

.return-to-menu-button #Group_180 {
    width: 16.422%;
    height: auto;
}

.return-to-menu-button:hover #Group_180 {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(263deg) brightness(103%) contrast(101%);
}

.IOI-history-table {
    height: 58.779%;
    width: 94.234%;
    top: 19.695%;
    left: 2.807%;
    border-radius: 14px;
    position: absolute;
}

.IOI-history-table-header {
    text-align: center;
    font: normal normal bold calc(13*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
    width: 100%;
    height: 8.333%;
    background: #F4F4F4 0% 0% no-repeat padding-box;
    border: 1px solid #E3E3E3;
    border-radius: 14px 14px 0px 0px;
    display: flex;
    align-items: center;
}

.IOI-history-table-header .up-down-sort-container {
    width: calc(12*var(--responsiveFont));
    margin-left: 5%;
}

.IOI-history-table-footer {
    text-align: left;
    font: normal normal normal calc(10*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #575757;
    width: 100%;
    height: 8.333%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E3E3E3;
    border-radius: 0px 0px 14px 14px;
    display: flex;
    align-items: center;
}

.IOI-history-table-footer span:first-of-type {
    font-weight: bold;
    margin-left: 3%;
}

.IOI-history-table-entries {
    width: calc(100% + 2px);
    height: 83.334%;
    overflow: auto;
    z-index: 1;
    position: relative;
}

.IOI-history-table-entries .IOI-history-entry:nth-child(even) {
    background-color: #F7F7F7;
}

.IOI-history-entry {
    height: 12.5%;
    display: flex;
    text-align: center;
    align-items: center;
    font: normal normal normal calc(12*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
    border: 1px solid #E3E3E3;
    overflow-y: clip;
}


.IOI-entry-index {
    text-align: center;
    font: normal normal normal calc(13*var(--responsiveFont))/calc(10*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #000000;
    height: 100%;
    width: 2.094%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-right: 1px solid #E3E3E3;
    border-left: 1px solid #E3E3E3;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.IOI-entry-status-bar{
    width: .805%;
    height: 100%;
    background: #4169E1 0% 0% no-repeat padding-box;
    border-right: 1px solid #E3E3E3;
    border-left: 1px solid #E3E3E3;
}

.IOI-entry-security {
    width: 13.630%;
    height: 100%;
    margin-left: 0.684%;
    margin-right: 1.376%;
    text-align: left;
    font: normal normal normal calc(13*var(--responsiveFont))/calc(10*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 2.5%;
}

.IOI-entry-security-checkbox{
    height: 39.9%;
    width: 1.41%;
    border-radius: 50%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #B1B1B1;
    margin-right: 1.149%;
}

.IOI-entry-security-checkbox-header-active{
    height: 49.9%;
    width: 1.375%;
    border-radius: 50%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #B1B1B1;
    margin-right: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.IOI-entry-security-checkbox-empty{
    height: 39.9%;
    width: 1.41%;
    border-radius: 50%;
    background: transparent 0% 0% no-repeat padding-box;
    border: 1px solid transparent;
    margin-right: 1.149%;
}

/* Hide the browser's default checkbox */
.IOI-entry-security-checkbox input {
position: relative;
opacity: 0;
cursor: pointer;
height: 100%;
width: 100%;
z-index: 5;
left: 0;
top: 0;
margin: 0;
}

.IOI-entry-security-checkbox-header-active input {
    position: relative;
    opacity: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
    z-index: 5;
    left: 0;
    top: 0;
    margin: 0;
}

/* Create a custom checkbox */
.checkmark {
position: relative;
top: 0;
left: 0;
height: 100%;
width: 100%;
background-color: transparent;
display: inline-block;
}

/* When the checkbox is checked, add a blue background */
.IOI-entry-security-checkbox input:checked ~ .checkmark {
background-color: transparent;
}

.IOI-entry-security-checkbox-header-active input:checked ~ .checkmark {
background-color: transparent;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
content: "";
position: absolute;
display: none;
}

/* Show the checkmark when checked */
.IOI-entry-security-checkbox input:checked ~ .checkmark:after {
display: block;
}

.IOI-entry-security-checkbox-header-active input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.IOI-entry-security-checkbox .checkmark:after {
left: 25%;
top: -107%;
width: calc(5*var(--responsiveFont));;
height: calc(10*var(--responsiveFont));;
border: solid #0D1265;
border-width: 0 calc(3*var(--responsiveFont)) calc(3*var(--responsiveFont)) 0;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg);
position: relative;
}

.IOI-entry-security-checkbox-header-active .checkmark:after {
right: 75%;
width: calc(5*var(--responsiveFont));;
height: calc(10*var(--responsiveFont));;
border: solid #0D1265;
border-width: 0 calc(3*var(--responsiveFont)) calc(3*var(--responsiveFont)) 0;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg);
position: relative;
}

.IOI-entry-direction-buy {
    width: 6.488%;
    height: 100%;
    background: #0EAA3B 0% 0% no-repeat padding-box;
    text-align: center;
    font: normal normal bold calc(12*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.IOI-entry-direction- {
    width: 6.488%;
    height: 100%;
    background: #0EAA3B 0% 0% no-repeat padding-box;
    text-align: center;
    font: normal normal bold calc(12*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.IOI-entry-direction-empty {
    width: 6.488%;
    height: 100%;
    background: transparent 0% 0% no-repeat padding-box;
    text-align: center;
    font: normal normal bold calc(12*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #E3E3E3;
    border-left: 1px solid #E3E3E3;
}

.IOI-entry-direction-sell {
    width: 6.488%;
    height: 100%;
    background: #BC3C3C 0% 0% no-repeat padding-box;
    text-align: center;
    font: normal normal bold calc(12*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.IOI-entry-quantity {
    width: 7.488%;
    height: 100%;
    border-right: 1px solid #E3E3E3;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.IOI-entry-type {
    width: 6.441%;
    height: 100%;
    border-right: 1px solid #E3E3E3;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.IOI-entry-usd {
    width: 9.81%;
    height: 100%;
    border-right: 1px solid #E3E3E3;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.IOI-entry-jpy {
    width: 9.406%;
    height: 100%;
    border-right: 1px solid #E3E3E3;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.IOI-entry-status-column {
    width: 10.628%;
    height: 100%;
    border-right: 1px solid #E3E3E3;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.IOI-entry-status-div {
    text-align: center;
    font: normal normal bold calc(12*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #FFFFFF;
    border-radius: 5px;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.IOI-entry-time-remaining {
    width: 11.272%;
    height: 100%;
    border-right: 1px solid #E3E3E3;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.IOI-entry-quantity-remaining {
    width: 13.366%;
    height: 100%;
    border-right: 1px solid #E3E3E3;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.IOI-entry-percent-remaining {
    width: 10.69%;
    height: 100%;
    border-right: 1px solid #E3E3E3;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*/////////////////////////////////////////////////////////////////////////////////////////////////*/

.IOI-entry-index-header {
    height: 100%;
    width: calc(2.094% - 2px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px 0px 0px 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.IOI-entry-security-header {
    width: 16.495%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.IOI-entry-security-checkbox-header{
    height: 49.9%;
    width: 1.375%;
    border-radius: 50%;
    background: #E0E0E0 0% 0% no-repeat padding-box;
    border: 1px solid #B1B1B1;
    margin-right: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.IOI-entry-direction-header {
    width: 6.3%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #E3E3E3;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.IOI-entry-quantity-header {
    width: 7.3%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.IOI-entry-type-header {
    width: 6.3%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.IOI-entry-usd-header {
    width: 9%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.IOI-entry-jpy-header {
    width: 9.406%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.IOI-entry-status-column-header {
    width: 10.628%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.IOI-entry-time-remaining-header {
    width: 10.272%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.IOI-entry-quantity-remaining-header {
    width: 13.366%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.IOI-entry-percent-remaining-header {
    width: 10.69%;
    height: 100%;
    border-radius: 0px 0px 0px 14px;
    margin-right: 0.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.up-down-sort-container {
    height: 100%;
    width: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.triangle-container {
    background-color: #E0E0E0;
    height: 30%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20%;
}

.triangle-container.sort-direction {
    background-color: #ACACAC;
}

.up-triangle{
    width: 0;
	height: 0;
	border-left: 3px solid transparent;
	border-right: 3px solid transparent;
	border-bottom: 6px solid #FFFFFF;
}

.down-triangle{
    width: 0;
	height: 0;
	border-left: 3px solid transparent;
	border-right: 3px solid transparent;
	border-top: 6px solid #FFFFFF;
}

.sent {
    background-color: #4169E1;
}

.live {
    background-color: #4169E1;
}

.paused {
    background-color: #E8C400;
}

.dead {
    background-color: #C64B8C;
}

.located {
    background-color: #0D1265;
}

.accept\/decline\? {
    background-color: #0D1265;
}

.unsent {
    background-color: #0D1265;
}

.accept\/decline\? {
    background-color: #0D1265;
}

.unsent {
    background-color: #0D1265;
}

.completed {
    background-color: #000000;
}

.empty {
    background-color: transparent;
}
