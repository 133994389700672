:root {
    --responsiveFont: calc(100vw / 1440);
}

.containers {
    -webkit-transition: width 1s, height 1s; /* Safari */
    transition: width 1s, height 1s;
}

.positionTransition {
    transition: top 1s, left 1s, width  1s ease-in-out, height 1s steps(1,jump-start);
}

.advert-advanced-container {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #D8D8D8;
    border-radius: 29px;
    height: calc(88.125vw * 0.563436);
    width: calc(88.125vw);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.advert-basic-container {
    height: calc(88.125vw * 0.462568);
    width: calc(88.125vw );
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #D8D8D8;
    border-radius: 29px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.advert-minimal-container {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #D8D8D8;
    border-radius: 29px;
    height: calc(88.125vw * 0.16233);
    width: calc(88.125vw); 
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto; 
}

.advert-history-container {
    height: calc(91.527778vw * 0.4969651);
    width: calc(91.527778vw);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #D8D8D8;
    border-radius: 29px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.broker-home-container {
    height: calc(91.527778vw * 0.5424886191);
    width: calc(91.527778vw);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #D8D8D8;
    border-radius: 29px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.profile-setup-container {
    height: calc(91.527778vw * 0.5424886191);
    width: calc(91.527778vw);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #D8D8D8;
    border-radius: 29px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

@media screen and (min-aspect-ratio: 1269/715) {
    :root {
        --responsiveFont: calc(100vh / 715);
    }

    .advert-basic-container {
        height: calc(0.462568 * calc(((88.125 * 100vh / (100 * (715/1440))) - calc(88.125vw * 0.01 )) ));
        width: calc(((88.125 * 100vh / (100 * (715/1440))) - calc(88.125vw * 0.01 )) );
    }
    .advert-advanced-container {
        height: calc(0.563436 * calc(((88.125 * 100vh / (100 * (715/1440))) - calc(88.125vw * 0.01 )) ) );
        width: calc(((88.125 * 100vh / (100 * (715/1440))) - calc(88.125vw * 0.01 )) );
    }
    .advert-minimal-container {
        height: calc( 0.16233 * calc(((88.125 * 100vh / (100 * (715/1440))) - calc(88.125vw * 0.01 )) ));
        width: calc(((88.125 * 100vh / (100 * (715/1440))) - calc(88.125vw * 0.01 )) );
    }
}

@media screen and (min-aspect-ratio: 1318/655) {
    .advert-history-container {
        height: calc( 0.4969651 * calc(((91.527778 * 100vh / (100 * (655/1440))) - calc(91.527778vw * 0.01 )) ));
        width: calc(((91.527778 * 100vh / (100 * (655/1440))) - calc(91.527778vw * 0.01 )) );
    }
}

@media screen and (min-aspect-ratio: 1318/715) {
    .broker-home-container {
        height: calc( 0.5424886191 * calc(((91.527778 * 100vh / (100 * (715/1440))) - calc(91.527778vw * 0.01 )) ));
        width: calc(((91.527778 * 100vh / (100 * (715/1440))) - calc(91.527778vw * 0.01 )) );
    }
}

@media screen and (min-aspect-ratio: 1318/715) {
    .profile-setup-container {
        height: calc( 0.5424886191 * calc(((91.527778 * 100vh / (100 * (715/1440))) - calc(91.527778vw * 0.01 )) ));
        width: calc(((91.527778 * 100vh / (100 * (715/1440))) - calc(91.527778vw * 0.01 )) );
    }
}

.user-background-container {
    height: 100vh;
    width: 100vw;
    position: absolute;
    display: flex;
    background: #050727 image-set(url("../../public/Waves.png") 1x, url("../../public/Waves@2x.png") 2x) 0% 0% repeat padding-box;
}