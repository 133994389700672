.profile-ioi-counts-container {
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  overflow: auto;
  padding: 2rem;
}

.profile-ioi-counts-container .form-field > label {
  font-weight: 700;
}