.copy-summary-button {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #0D1265;
    width: 17.728%;
    height: 6.294%;
    border-radius: 29px;
    text-align: center;
    font: normal normal 600 calc(14*var(--responsiveFont))/calc(30*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
    top: 87.215%;
    left: 41.61457%;
    position: absolute;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.copy-summary-button:hover {
    background-color: #0D1265;
    color: #FFFFFF;
}

.copy-summary-button #Group_38 {
    width: 7%;
    height: auto;
}

.copy-summary-button:hover #Group_38 {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(263deg) brightness(103%) contrast(101%);
}

.email-summary-button {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #0D1265;
    width: 13.8088%;
    height: 6.294%;
    border-radius: 29px;
    text-align: center;
    font: normal normal 600 calc(14*var(--responsiveFont))/calc(30*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
    top: 87.215%;
    left: 54.626%;
    position: absolute;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.email-summary-button:hover {
    background-color: #0D1265;
    color: #FFFFFF;
}

#mail_icon {
    width: 10.989%;
    height: auto;
    fill: #0D1265;
}

.email-summary-button:hover #mail_icon {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(263deg) brightness(103%) contrast(101%);
}

.broker-summary-main {
    height: 65.5944%;
    width: 94.20257867%;
    top: 16.45175%;
    left: 2.8475%;
    position: relative;
}

.broker-summary-main-header {
    width: 100%;
    height: 13.4328%;
    border-radius: 29px 29px 0px 0px;
    background-repeat: repeat;
    background-image: image-set(url("../../public/Waves.png") 1x, url("../../public/Waves@2x.png") 2x);
    background-color: #FFCC00;
    background-size: 50%;
    background-blend-mode: exclusion;
    text-align: center;
    font: normal normal bold calc(22*var(--responsiveFont))/calc(20*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0F0E69;
    display: flex;
    align-items: center;
    justify-content: center;
}

.broker-summary-table {
    height: 86.5672%;
    width: 100%;
    position: relative;
}

.broker-summary-table-header {
    text-align: center;
    font: normal normal bold calc(13*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
    width: 100%;
    height: 8.333%;
    background: #F4F4F4 0% 0% no-repeat padding-box;
    border-top: 1px solid #E3E3E3;
    border-bottom: 1px solid #E3E3E3;
    display: flex;
    align-items: center;
}

.broker-summary-table-header .up-down-sort-container {
    width: calc(12*var(--responsiveFont));
    margin-left: 5%;
}

.broker-summary-table-footer {
    text-align: left;
    font: normal normal normal calc(10*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #575757;
    width: calc(100% - 2px);
    height: 8.333%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E3E3E3;
    border-radius: 0px 0px 14px 14px;
    display: flex;
    align-items: center;
}

.broker-summary-table-footer span:first-of-type {
    font-weight: bold;
    margin-left: 3%;
}

.broker-summary-table-entries {
    width: 100%;
    height: 83.334%;
    overflow: overlay;
    z-index: 1;
    position: relative;
}

.broker-summary-table-entries .broker-summary-entry:nth-child(even) {
    background-color: #F7F7F7;
}

.broker-summary-entry {
    height: 12.5%;
    width: 99.8%;
    display: flex;
    text-align: center;
    align-items: center;
    font: normal normal normal calc(12*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
    border: 1px solid #E3E3E3;
    overflow-y: clip;
}


.agreement-entry-index {
    text-align: center;
    font: normal normal normal calc(13*var(--responsiveFont))/calc(10*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #000000;
    height: 100%;
    width: 2.0508%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-right: 1px solid #E3E3E3;
    border-left: 1px solid #E3E3E3;
}

.agreement-entry-security {
    width: 16.4455%;
    height: 100%;
    margin-left: 0.684%;
    margin-right: 1.376%;
    text-align: left;
    font: normal normal normal calc(13*var(--responsiveFont))/calc(10*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 2.5%;
}

.agreement-entry-security-checkbox{
    height: 39.9%;
    width: 1.41%;
    border-radius: 50%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #B1B1B1;
    margin-right: 1%;
}

.empty-row-checkbox-space{
    height: 39.9%;
    width: 1.41%;
    border-radius: 50%;
    background: transparent 0% 0% no-repeat padding-box;
    margin-right: 1%;
}

/* Hide the browser's default checkbox */
.agreement-entry-security-checkbox input {
position: relative;
opacity: 0;
cursor: pointer;
height: 100%;
width: 100%;
left: 0;
top: 0;
margin: 0;
z-index: 5;
}

/* Create a custom checkbox */
.checkmark-broker {
position: relative;
top: 0;
left: 0;
height: 100%;
width: 100%;
background-color: transparent;
display: inline-block;
}

/* When the checkbox is checked, add a blue background */
.agreement-entry-security-checkbox input:checked ~ .checkmark-broker {
background-color: transparent;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-broker:after {
content: "";
position: absolute;
display: none;
}

/* Show the checkmark when checked */
.agreement-entry-security-checkbox input:checked ~ .checkmark-broker:after {
display: block;
}

/* Style the checkmark/indicator */
.agreement-entry-security-checkbox .checkmark-broker:after {
left: 28%;
top: -105%;
width: calc(5*var(--responsiveFont));
height: calc(10*var(--responsiveFont));
border: solid #0D1265;
border-width: 0 calc(3*var(--responsiveFont)) calc(3*var(--responsiveFont)) 0;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg);
position: relative;
}

.agreement-entry-quantity {
    width: 9.2425%;
    height: 100%;
    border-right: 1px solid #E3E3E3;
    border-left: 1px solid #E3E3E3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.agreement-entry-price {
    width: 6.7873%;
    height: 100%;
    border-right: 1px solid #E3E3E3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.agreement-entry-type {
    width: 10.0048%;
    height: 100%;
    border-right: 1px solid #E3E3E3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.agreement-entry-password {
    width: 10.8985%;
    height: 100%;
    border-right: 1px solid #E3E3E3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.agreement-entry-status-column {
    width: 8.7550%;
    height: 100%;
    border-right: 1px solid #E3E3E3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.agreement-entry-status-div {
    text-align: center;
    font: normal normal bold calc(12*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #FFFFFF;
    border-radius: 5px;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
    cursor: pointer;
}

.agreement-entry-timestamp {
    width: 12%;
    height: 100%;
    border-right: 1px solid #E3E3E3;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*/////////////////////////////////////////////////////////////////////////////////////////////////*/

.agreement-entry-index-header {
    height: 100%;
    width: calc(2.0508% - 2px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px 0px 0px 0px;
}

.agreement-entry-security-header {
    width: calc(20.9155% - 2px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.agreement-entry-quantity-header {
    width: calc(9.2425% - 2px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.agreement-entry-price-header {
    width: calc(6.7873% - 1px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.agreement-entry-type-header {
    width: calc(10.0048% - 1px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.agreement-entry-password-header {
    width: calc(10.8985% - 1px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.agreement-entry-status-header {
    width: calc(8.7550% - 1px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.agreement-entry-timestamp-header {
    width: calc(12% - 1px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.up-down-sort-container {
    height: 100%;
    width: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.triangle-container {
    background-color: #E0E0E0;
    height: 30%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20%;
}

.triangle-container.sort-direction {
    background-color: #ACACAC;
}

.up-triangle{
    width: 0;
	height: 0;
	border-left: calc(3*var(--responsiveFont)) solid transparent;
	border-right: calc(3*var(--responsiveFont)) solid transparent;
	border-bottom: calc(6*var(--responsiveFont)) solid #FFFFFF;
}

.down-triangle{
    width: 0;
	height: 0;
	border-left: calc(3*var(--responsiveFont)) solid transparent;
	border-right: calc(3*var(--responsiveFont)) solid transparent;
	border-top: calc(6*var(--responsiveFont)) solid #FFFFFF;
}

.Received {
    background-color: #295535;
    user-select: none;
    cursor: default;
}

.Pending {
    background-color: #E8C400;
    user-select: none;
}