.profile-setup-main {
    height: 65.5944%;
    width: 94.20257867%;
    top: 16.45175%;
    left: 2.8475%;
    position: relative;
}

.profile-setup-main-header {
    width: 100%;
    height: 13.4328%;
    border-radius: 29px 29px 0px 0px;
    background-repeat: repeat;
    background-image: image-set(url("../../public/Waves.png") 1x, url("../../public/Waves@2x.png") 2x);
    background-color: #FFCC00;
    background-size: 50%;
    background-blend-mode: exclusion;
    text-align: center;
    font: normal normal bold calc(22*var(--responsiveFont))/calc(20*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0F0E69;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-setup-main-message {
    width: 100%;
    height: 13.4328%;
    border-radius: 29px 29px 0px 0px;
    text-align: center;
    font: normal normal bold calc(20*var(--responsiveFont))/calc(18*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0F0E69;
    display: flex;
    align-items: center;
    justify-content: center;
}

.submit-password-button {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #0D1265;
    width: 13.8088%;
    height: 6.294%;
    border-radius: 29px;
    text-align: center;
    font: normal normal 600 calc(14*var(--responsiveFont))/calc(30*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
    top: 67.215%;
    left: 42%;
    position: absolute;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.setup-form-input {
    border-style: none;
    width: 80%;
    font: normal normal 500 calc(18*var(--responsiveFont))/calc(17*var(--responsiveFont)) NotoSans;
}
.password-form {
    position: absolute;
    width: 40%;
    height: 7%;
    left: 40%;
    top: 37%;
    overflow: visible;
}

.password-input {
    left: 5%;
    top: 16%;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: left;
    font: normal normal 500 calc(18*var(--responsiveFont))/calc(17*var(--responsiveFont)) NotoSans;
    color: rgba(150,150,159,1);
}

.password {
    left: 26%;
    top: 39%;
    position: absolute;
    overflow: visible;
    white-space: nowrap;
    text-align: left;
    font: normal normal 500 calc(18*var(--responsiveFont))/calc(17*var(--responsiveFont)) NotoSans;
    color: rgba(0,0,0,1);
}

.confirm-password-form {
    position: absolute;
    width: 40%;
    height: 7%;
    left: 40%;
    top: 50%;
    overflow: visible;
}

.confirm-password {
    left: 26%;
    top: 52%;
    position: absolute;
    overflow: visible;
    white-space: nowrap;
    text-align: left;
    font: normal normal 500 calc(18*var(--responsiveFont))/calc(17*var(--responsiveFont)) NotoSans;
    color: rgba(0,0,0,1);
}

#Rectangle_password_gt {
    fill: rgba(255,255,255,1);
    stroke: rgba(227,226,230,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Rectangle_password_gt {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
}
