.password-reset-basic-popup {
    width: 33.7%;
    height: 41.6%;
    background: #FCFCFC 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #E3E3E3;
    border-radius: 29px;
    opacity: 1;
    z-index: 100;
    position: absolute;
    left: 33.76%;
    top: 29.2%;
}

.password-reset-top {
    width: 100%;
    height: 23.5%;
    background: #5D0505 0% 0% no-repeat padding-box;
    border-radius: 29px 29px 0px 0px;
    opacity: 1;
    text-align: center;
    font: normal normal bold calc(22*var(--responsiveFont))/calc(20*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #FCFCFC;
    opacity: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.password-reset-body {
    width: 100%;
    height: 76.5%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.password-reset-message {
    height: 0%;
    width: 92%;
    text-align: center;
    font: normal normal 600 calc(14*var(--responsiveFont))/calc(18*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
}

.password-reset-buttons {
    height: 25%;
    display: flex;
    width: 83%;
    justify-content: space-evenly;
    align-items: center;
}

.password-reset-button {
    width: 30%;
    height: 100%;
    background: #7D1B25 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #7D1B24;
    border-radius: 14px;
    opacity: 1;
    text-align: center;
    font: normal normal 600 calc(14*var(--responsiveFont))/calc(18*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #FCFCFC;
}

.password-reset-button:hover {
    background: #982D39 0% 0% no-repeat padding-box;
    border: 2px solid #982D38;
    color: #FFFFFF;
}