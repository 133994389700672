.profile-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 2rem;
}

.profile-container-inner {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  min-height: 50%;
  overflow: auto;
  border-radius: 2rem;
}

.profile-header {
  position: initial;
}

.profile-content {
  /* padding: 1rem 2rem; */
}

.tabs {
  margin: 1rem 2rem;
}