body {
  overflow-x: hidden;
}

.carousel-header {
  font-family: Forum;
  color: #0D1265;
}

.slide {
  margin: auto;
  width: 250px;
  height: 80px;
  display: flex;
  align-items: center;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}