/* Mobile Styling */
.mobile-about-us-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center everything on the page */
    text-align: center;
    padding: 20px;
    width: 100%;
}
  
/* Video Section (Centered with Controls) */
.video-container {
    width: 100%;
    display: flex;
    justify-content: center; /* Centers the video */
    margin-bottom: 20px;
}

.about-us-video {
    width: 80%;
    max-width: 800px;
    height: auto;
}

/* MOBILE: Carousel Section */
.carousel-container {
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;
}

/* Base styling for carousel images */
.carousel-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

/* Active image (fading effect) */
.carousel-image.active {
    opacity: 1;
}

/* TEXT SECTION */
.text-section {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the section */
    max-width: 60%;
    margin-top: 20px;
}

/* About Us Header (Centered Above the Text) */
.about-us-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
}

/* About Us Text (Left-Aligned Below the Header) */
.about-us-text {
    font-size: 16px;
    text-align: left;
    width: 100%;
}

/* Footer Watermark (Centered) */
.footer-watermark {
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
}

/* DESKTOP VIEW: Side-by-Side Images */
@media screen and (min-width: 768px) {
    /* Desktop container */
    .desktop-about-us-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 40px;
        width: 100%;
    }

    /* Desktop: Display images side by side */
    .image-grid {
        display: flex;
        flex-direction: row;
        justify-content: center; /* Centers images */
        align-items: center;
        gap: 20px; /* Adds space between images */
        flex-wrap: wrap;
        width: 100%;
        padding: 20px 0;
    }

    /* Individual images in the grid */
    .grid-image {
        width: 30%;  /* Adjust to fit screen */
        max-width: 400px;
        height: auto;
        object-fit: cover;
        border-radius: 5px;
    }
    /* Default Bio Section Styling */
    .bio-section {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 40px 0;
    }

    .bio-section {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 40px 0;
      }
      
      .bio-container {
        display: flex;
        flex-direction: column; /* Stack bios vertically */
        gap: 20px;
        width: 100%;
      }
      
      .bio-item {
        display: flex;
        align-items: flex-start; /* Align items to the top */
        text-align: left;
        width: 100%;
        flex-wrap: wrap; /* Ensures text wraps */
      }
      
      .bio-image {
        width: 200px;  /* Default for desktop */
        height: auto;
        object-fit: cover;
        border-radius: 10px;
        margin-right: 20px;
        flex-shrink: 0; /* Prevents image from shrinking */
      }
      
      /* Ensure text wraps properly */
      .bio-text {
        font-size: 16px;
        color: #333;
        max-width: 600px; /* Prevents text from stretching too wide */
        word-wrap: break-word; /* Forces words to break if necessary */
        white-space: normal; /* Allows text to wrap */
        overflow-wrap: break-word; /* Ensures long words wrap */
      }
      
      /* Mobile Styles */
        @media (max-width: 768px) {
        .bio-item {
          flex-direction: row; /* Keep image & text side by side */
          align-items: center;
          flex-wrap: wrap; /* Ensures text wraps properly */
        }
        /* Add extra spacing above the bio section on mobile */
        @media (max-width: 768px) {
            .bio-section {
            margin-top: 60px; /* Adds extra space equivalent to two line breaks */
            }
        }
      
        .bio-image {
          width: 100px; /* Reduce image size for mobile */
          height: auto;
          margin-right: 10px;
        }
      
        .bio-text {
          font-size: 14px;
          max-width: 70%;
          word-wrap: break-word; /* Ensures text wraps */
          overflow-wrap: break-word; /* Forces long words to break */
          white-space: normal; /* Prevents text from staying on one line */
        }
        
      }
}