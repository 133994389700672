.btn {
    background: #11055E;
    border: 1px solid #11055E;
    border-radius: 1.5rem;
    font-weight: 500;
    text-align: center;
    color: #FFFFFF;
    padding: 0.5rem 1rem;
    min-width: 5rem;
    font-family: NotoSans;
    cursor: pointer;
}

.btn:disabled {
    background-color: #918ea9;
    border-color: #918ea9;
    pointer-events: none;
}

.btn-outlined {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #10055D;
    border-radius: 1.5rem;
    text-align: center;
    color: #11055E;
    padding: 0.5rem 1rem;
    min-width: 5rem;
    font-family: NotoSans;
    text-decoration: none;
    cursor: pointer;
}

.btn-outlined.icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}

.btn-outlined.icon > .icon {
    width: 2rem;
    aspect-ratio: 1;
    filter: brightness(0) saturate(100%) invert(7%) sepia(45%) saturate(7257%) hue-rotate(239deg) brightness(99%) contrast(106%);
}

.btn-outlined.icon:hover > .icon {
    filter: brightness(0) invert(1);
}

.btn-outlined:hover {
    background: #190981 0% 0% no-repeat padding-box;
    color: #FFFFFF;
}

.btn-small {
    min-width: auto;
    padding: 0.2rem 0.5rem;
}
