.IOI-advanced-window {
    width: 70.68%;
    height: 54.41%;
    position: absolute;
    top: 25.815%;
    left: 26.005%;
}

.IOI-window-advanced-expand-selling {
    width: 100%;
    height: 59.27%;
    border-radius: 0px 0px 29px 29px;
    border-left: 1px solid #E3E3E3;
    border-right: 1px solid #E3E3E3;
    border-bottom: 1px solid #E3E3E3;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
}

.IOI-window-advanced-expand-buying {
    width: 100%;
    height: 59.27%;
    border-radius: 0px 0px 29px 29px;
    border-left: 1px solid #E3E3E3;
    border-right: 1px solid #E3E3E3;
    border-bottom: 1px solid #E3E3E3;
    background-image: image-set(
		url("/public/Group_949_b.png") 1x,
		url("/public/Group_949_b@2x.png") 2x), image-set(
            url("/public/Group_936.png") 1x,
            url("/public/Group_936@2x.png") 2x);
    background-position: right bottom, left bottom;
    background-size: 25.98% 87.88%, 23.01% 81.41%;
    background-repeat: no-repeat;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
}

.IOI-window-advanced-expand-selling::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 59.27%;
    background-image: image-set(
		url("/public/Group_949_b.png") 1x,
		url("/public/Group_949_b@2x.png") 2x), image-set(
            url("/public/Group_936.png") 1x,
            url("/public/Group_936@2x.png") 2x);
    background-position: right bottom, left bottom;
    background-size: 25.98% 87.88%, 23.01% 81.41%;
    background-repeat: no-repeat;
    background-color: transparent;
    filter: brightness(0) saturate(100%) invert(30%) sepia(62%) saturate(921%) hue-rotate(318deg) brightness(97%) contrast(100%);
}

.IOI-window-advanced-options {
    display: flex;
    align-items: center;
    width: 100%;
    height: 16.4%;
    margin-top: 3.8%;
}

.price-conditions-menu {
    height: 54.12%;
    width: 57.2%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 3.74%;
    z-index: 3;
    overflow: hidden;
}

.price-condition-option-labels {
    display: flex;
    flex-direction: column;
    text-align: right;
    font: normal normal normal calc(14*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #000000;
    width: 30.32%;
    height: 100%;
}

.price-condition-option-labels div {
    margin-bottom: 15%;
    height: 15.23%;
    width: 100%;
}

.price-condition-option-toggle-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 6.24%;
}

.price-condition-option-toggle {
    width: 100%;
    height: 12.82%;
    margin-bottom: 70%;
    background: #B9BFCB 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.price-condition-option-toggle-active {
    width: 100%;
    height: 12.82%;
    margin-bottom: 70%;
    background: #101683 0% 0% no-repeat padding-box;
    border: 1px solid #2E66E9;
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.toggle-circle{
    width: 37.5%;
    height: 75%;
    border-radius: 50%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    margin-left: 6.25%;
    margin-right: 6.25%;
}

.price-condition-option-value-container {
    width: 24.36%;
    height: 100%;
}

.price-condition-option-value-inactive {
    height: 17.23%;
    width: 100%;
    text-align: center;
    border-bottom: 2px solid #E9E9F0;
    font: normal normal 600 calc(14*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #4D4F5C;
    margin-bottom: 11%;
    opacity: 0.32;
}

.price-condition-option-value-active {
    height: 17.23%;
    width: 100%;
    text-align: center;
    border-bottom: 2px solid #E9E9F0;
    font: normal normal 600 calc(14*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #4D4F5C;
    margin-bottom: 11%;
}

.price-condition-variables {
    height: 74.26%;
    width: 25.91%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: 1px solid #707070;
    margin-top: 0;
}

.price-condition-variables div:first-child {
    margin-top: 12.4%;
}

.price-condition-variable {
    height: 19.423%;
    text-align: left;
    letter-spacing: 0px;
    color: #404040;
    font: normal normal 500 calc(13*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    margin-left: 17.1%;
    margin-bottom: 6.41%;
}

.advertising-preferences-menu {
    display: flex;
    flex-direction: column;
    z-index: 3;
    width: 85.59%;
    height: 68.36%;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
}

.advertising-preferences-options {
    display: flex;
    width: 100%;
    height: 31.83%;
    justify-content: space-around;
}

.credibility-filter-menu {
    display: flex;
    flex-direction: column;
    width: 16.3%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
}

.transaction-type-menu {
    display: flex;
    flex-direction: column;
    width: 18.78%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1%;
}

.duration-menu {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 18.78%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1%;
}

.quantity-decay-menu {
    display: flex;
    flex-direction: column;
    width: 13.56%;
    height: 100%;
    align-items: center;
}

.minimum-Q-menu {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 18%;
    align-items: center;
}

.advertising-preferences-option-label {
    text-align: center;
    font: normal normal normal calc(14*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #000000;
}

.duration-option-dropdown {
    width: 98%;
    height: 52%;
    text-align: center;
    font: normal normal 600 calc(12*var(--responsiveFont))/calc(30*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #0D1265;
    border-radius: 17px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 6.95%;
}

.duration-option-dropdown .Path_26_f {
    width: auto;
    height: 25%;
}

.duration-menu:hover .Path_26_f	{
    transform: matrix(-1,0,0,-1,0,0);
}

.duration-menu:hover .transaction-type-option-options {
    display: flex;
    z-index: 7;
}

.transaction-type-option-dropdown {
    width: 98%;
    height: 52%;
    text-align: center;
    font: normal normal 600 calc(12*var(--responsiveFont))/calc(30*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #0D1265;
    border-radius: 17px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 6.95%;
}

.transaction-type-option-dropdown .Path_26_f {
    width: auto;
    height: 25%;
}

.transaction-type-menu:hover .Path_26_f	{
    transform: matrix(-1,0,0,-1,0,0);
}

.transaction-type-menu:hover .transaction-type-option-options {
    display: flex;
    z-index: 7;
}

.transaction-type--option-options:hover {
    display: flex;
    z-index: 7;
}

.transaction-type-option-options span {
    margin-left: 8%;
    margin-bottom: 1%;
    cursor: pointer;
}

.transaction-type-option-options span:first-child {
    margin-top: 6%;
}

.transaction-type-option-options span:last-child {
    margin-bottom: 6%;
}

.transaction-type-option-options {
    position: relative;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    text-align: left;
    font: normal normal normal calc(12*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #4D4F5C;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #707070;
    border-radius: 9px;
    opacity: 1;
}

.transaction-type-option-options span:hover {
    font: normal normal 600 calc(12*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    color: #0D1265;
}

.minimum-q-slider{
    height: 20%;
    width: 100%;
    margin-top: 7.97%;
}

/* The slider itself */
.minimum-q-slider-input {
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: 100%; /* Full-width */
    height: 45%; /* Specified height */
    outline: none; /* Remove outline */
    opacity: 1; /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    border-radius: 999px;
}

/* Mouse-over effects */
.minimum-q-slider-input:hover {
    opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.minimum-q-slider-input::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 10px; /* Set a specific slider handle width */
    height: 10px; /* Slider handle height */
    background: #0D1265; /* Green background */
    cursor: pointer; /* Cursor on hover */
    border-radius: 50%;
}

.minimum-q-slider-input::-webkit-slider-thumb:hover {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 14px; /* Set a specific slider handle width */
    height: 14px; /* Slider handle height */
}

.minimum-q-slider-input:hover + .minimum-q-slider-input-hover-value {
    display: flex;
}

.minimum-q-slider-input::-moz-range-thumb {
    width: 10px; /* Set a specific slider handle width */
    height: 10px; /* Slider handle height */
    border-radius: 50%;
    background: #0D1265; /* Green background */
    cursor: pointer; /* Cursor on hover */
}

.minimum-q-slider-input-hover-value {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #0D1265;
    text-align: left;
    font: normal normal 500 calc(14*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0C1164;
    display: none;
    height: 369.2%;
    width: 26.78%;
    border-radius: 50%;
    position:relative;
    align-items: center;
    justify-content: center;
    top: -529%;
    left: -7%;
}

.quantity-decay-toggle-container {
    width: 31.86%;
    height: 31.9%;
    margin-top: 13.5%;
}

.quantity-decay-option-toggle {
    width: 100%;
    height: 100%;
    background: #B9BFCB 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.quantity-decay-option-toggle-active {
    width: 100%;
    height: 100%;
    background: #101683 0% 0% no-repeat padding-box;
    border: 1px solid #2E66E9;
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.set-default-button {
    width: 13.92%;
    height: 16.5%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #0D1265;
    border-radius: 17px;
    text-align: center;
    font: normal normal 600 calc(12*var(--responsiveFont))/calc(30*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
    display: flex;
    justify-content: center;
    align-items: center;
}

.set-default-button:hover {
    background: #0D1265 0% 0% no-repeat padding-box;
    color: #FCFCFC;
}

.set-default-button-multi-row:hover {
    background: #0D1265 0% 0% no-repeat padding-box;
    color: #FCFCFC;
}

.set-default-button-multi-row {
    width: 14.92%;
    height: 50%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #0D1265;
    border-radius: 17px;
    text-align: center;
    font: normal normal 600 calc(12*var(--responsiveFont))/calc(30*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    margin-left: 1%;
}

.path-preferences-menu-multi-row {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 50.36%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0%;
    margin-bottom: 0%;
    padding-top: 0%;
    width: 85.59%;
    z-index: 3;
}

.path-preferences-menu {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 68.36%;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    width: 85.59%;
    z-index: 3;
}

.path-preferences-options {
    display: flex;
    width: 100%;
    height: 40%;
    align-items: center;
    justify-content: center;
}

.path-preferences-options-multi-row {
    display: flex;
    width: 100%;
    height: 60%;
    align-items: center;
    justify-content: center;
}

.broker-rank-row {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10%;
    flex-direction: row;
    height: 100%;
    width: 80%;
    position: relative;
    box-sizing: border-box;
    justify-content: center;
    margin-left: 1.5%;
    
}

.broker-rank-multi-row {
    display: flex;
    flex-direction: column;
    height: 90.5%;
    width: 23%;
    margin-right: 1.5%;
    position: relative;
}

.broker-rank {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-right: 1.5%;
    position: relative;
    width: 23%;
}

.broker-rank-label {
    text-align: center;
    font: normal normal normal calc(14*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #000000;
    width: 100%;
    height: 30.159%;
}

.broker-option-drag-drop {
    text-align: center;
    font: normal normal normal calc(14*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #FFFFFF;
    background: #000000 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 4px;
    height: 50.794%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab;
    justify-content: space-between;
}

.broker-option-drag-drop:active {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing;
}

.broker-option-drag-drop:hover {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

.broker-rank:hover .broker-option-drag-drop:not(:active) + .broker-option-dropdown-menu {
    display: flex;
}

.broker-rank-multi-row:hover .broker-option-drag-drop:not(:active) + .broker-option-dropdown-menu {
    display: flex;
}

.broker-option-dropdown-menu:hover {
    display: flex;
}

.broker-option-dropdown-menu .broker-option-drag-drop {
    display: block;
}

.broker-option-dropdown-menu {
    padding-top: 5%;
    display: none;
    flex-direction: column;
    position: absolute;
    width: 140%;
    text-align: left;
    height: 180%;
    top: 95%;
    left: -20%;
    border-radius: 8px;
    background-color: #FFFFFF;
    border: 1px solid #e3e3e3;
    white-space: nowrap;
    overflow: scroll;
    z-index: 20;
}

.broker-option-dropdown-menu span {
    margin-left: 5%;
    margin-top: 2%;
    margin-bottom: 2%;
    cursor: pointer;
    font: normal normal normal calc(13*var(--responsiveFont))/calc(14*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #4D4F5C;
}

.broker-option-dropdown-menu span:hover {
    font: normal normal 600 calc(13*var(--responsiveFont))/calc(14*var(--responsiveFont)) NotoSans;
    color: #0D1265;
}

.broker-option-drag-drop-background {
    border: 1px dashed #DBDBDB;
    border-radius: 4px;
    height: 50.794%;
    width: 100%;
    z-index: 4;
}

.broker-option-text {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 70%;
    height: 50%;
    white-space: nowrap;
}

.rank-option {
    text-align: left;
    font: normal normal normal calc(14*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #000000;
    cursor: pointer;
    display: block;
    position: relative;
    padding-left: 30%; 
    margin-bottom: 22%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 50%;
    width: 100%;
}

/* Hide the browser's default radio button */
.rank-option input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .radioCheck {
    position: absolute;
    top: 0;
    left: 0;
    height: 99.836%;
    width: 13%;
    background-color: #FFFFFF;
    border: 1px solid #5C5C5C;
    border-radius: 50%;
  }
  
  /* When the radio button is checked, add a blue background */
  .rank-option input:checked ~ .radioCheck {
    background: radial-gradient(circle at center,#0D1265, #0D1265 51%, #FFFFFF 60%, #FFFFFF);
    border: 1px solid #5C5C5C;
  }

.rank-options-multi-row {
    display: flex;
    flex-direction: column;
    width: 11%;
    height: 77.014%;
    align-items: center;
    margin-right: 4.626%;
    margin-top: 2.5%;
}

.rank-options {
    display: flex;
    flex-direction: column;
    width: 10%;
    height: 82.014%;
    align-items: center;
    margin-right: 4.626%;
    margin-top: 2.5%;
}

.draggable-icon-container {
    width: 10%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 2.5%;
}

.draggable-icon-row {
    width: 100%;
    height: 25%;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.draggable-icon-row div {
    width: 20%;
    height: 44%;
    border-radius: 50%;
    background-color: rgba(85, 84, 84, 1);
}

.broker-option-drag-drop .Path_26_f {
    transform: matrix(-1,0,0,-1,0,0);
    margin-right: 5%;
    width: auto;
    height: 25%;
}

.broker-rank:hover .Path_26_f {
    transform: matrix(1,0,0,1,0,0);
}

.broker-rank-multi-row:hover .Path_26_f {
    transform: matrix(1,0,0,1,0,0);
}

.price-conditions-field-input {
    font: inherit;
    text-align: center;
    width: 100%;
    outline: none;
    border: none;
    background: none;
    text-overflow: ellipsis;
}

.price-condition-option-value-active .price-conditions-field-input:focus {
    border-bottom: 2px solid #101683;
    font: normal normal bold calc(14*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    color: #101683;
}