.profile-security-container {
  display: flex;
  flex-direction: row;
  padding: 0 2rem;
}

.profile-security-container > div {
  position: relative;
  flex: 1;
  padding: 0.5rem 1rem;
}

.profile-security-container > div:first-child {
  padding-left: 0;
}

.profile-security-container > div:last-child {
  padding-right: 0;
}

.profile-security-container > div:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 2px;
  background-color: #0D1265;
}

.reset-password-content {
  display: flex;
  flex-direction: column;
}

.reset-password-content .header {
  margin: 0  0 1.2rem 0;
}
