.bulk-upload-popup {
    position: absolute;
    z-index: 10;
    width: 428px;
    height: 494px;

    background: #FCFCFC 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #E3E3E3;
    border-radius: 29px;
    opacity: 1;
}

.bulk-upload-popup.minimal {
    position: relative;
    z-index: 10;
    width: 33.7273%;
    height: 239.8058%;
    margin: auto;
    top: -90%;
    background: #FCFCFC 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #E3E3E3;
    border-radius: 29px;
    opacity: 1;
}

.bulk-upload-popup.basic {
    position: relative;
    z-index: 10;
    width: 33.7273%;
    height: 84.1567%;
    margin: auto;
    top: -37%;
    background: #FCFCFC 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #E3E3E3;
    border-radius: 29px;
    opacity: 1;
}

.bulk-upload-popup.advanced {
    position: relative;
    z-index: 10;
    width: 33.7273%;
    height: 69.0909%;
    margin: auto;
    top: 17%;
    background: #FCFCFC 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #E3E3E3;
    border-radius: 29px;
    opacity: 1;
}

.bulk-upload-popup.history {
    position: relative;
    z-index: 10;
    width: 33.7273%;
    height: 69.0909%;
    margin: auto;
    top: 17%;
    background: #FCFCFC 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #E3E3E3;
    border-radius: 29px;
    opacity: 1;
}

.bulk-upload-confirmation-popup {
    position: absolute;
    z-index: 10;
    width: 890px;
    height: 494px;

    background: #FCFCFC 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #E3E3E3;
    border-radius: 29px;
    opacity: 1;
    overflow: hidden;
}

.bulk-upload-confirmation-popup.basic {
    position: relative;
    z-index: 10;
    margin: auto;
    width: 70.1694%;
    height: 84.1567%;
    top: -37%;

    background: #FCFCFC 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #E3E3E3;
    border-radius: 29px;
    opacity: 1;
}

.bulk-upload-confirmation-popup.advanced {
    position: relative;
    z-index: 10;
    margin: auto;
    width: 70.1694%;
    height: 69.0909%;
    top: 17%;

    background: #FCFCFC 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #E3E3E3;
    border-radius: 29px;
    opacity: 1;
}

.bulk-upload-confirmation-popup.history {
    position: relative;
    z-index: 10;
    margin: auto;
    width: 70.1694%;
    height: 69.0909%;
    top: 17%;

    background: #FCFCFC 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #E3E3E3;
    border-radius: 29px;
    opacity: 1;
}

.bulk-upload-confirmation-popup.minimal {
    position: relative;
    z-index: 10;
    margin: auto;
    width: 70.1694%;
    height: 239.8058%;
    top: -90%;

    background: #FCFCFC 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #E3E3E3;
    border-radius: 29px;
    opacity: 1;
}

.bulk-upload-popup-top {
    width: 100%;
    height: 11.5921%;
    background: #0D1265 0% 0% no-repeat padding-box;
    border-radius: 29px 29px 0px 0px;
    text-align: center;
    font: normal normal normal calc(22*var(--responsiveFont))/calc(20*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #FCFCFC;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/*#4169E1 for Sent
#F6C644 for Paused (no change)
#C64B8C for Cancelled*/

.bulk-file-drop {
    position: relative;
    top: 7%;
    margin-left: auto;
    margin-right: auto;
    width: 87.752%;
    height: 60.8942%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px dashed #C3C3C3;
    border-radius: 12px;
    opacity: 1;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bulk-file-drop-input:hover + .bulk-file-drop{
    border: 3px dashed #000000;
}

.cancel-button-bulk {
    margin-top: 11%;
    left: 18%;
    width: 28.7386%;
    height: 10.5022%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #C64B8C;
    border-radius: 14px;
    opacity: 1;
    text-align: center;
    font: normal normal 600 calc(14*var(--responsiveFont))/calc(18*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #1D1D1D;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.cancel-button-bulk:hover {
    background: #C64B8C 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #C64B8C;
    color: #FFFFFF;
}

.cancel-button-bulk-confirm {
    margin-top: 1%;
    left: 33.3734%;
    width: 11.9558%;
    height: 7.4263%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #C64B8C;
    border-radius: 14px;
    opacity: 1;
    text-align: center;
    font: normal normal 600 calc(14*var(--responsiveFont))/calc(18*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #1D1D1D;
    position: relative;
}

.cancel-button-bulk-confirm:hover {
    background: #C64B8C 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #C64B8C;
    color: #FFFFFF;
}

.drag_n_drop_text {
    width: 80%;
    height: 17.9402%;
    text-align: center;
    font: normal normal bold calc(21*var(--responsiveFont))/calc(26*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    opacity: 1;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.browse_file_text {
    width: 100%;
    height: 6.3123%;
    text-align: center;
    font: normal normal 500 calc(14*var(--responsiveFont))/calc(20*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    opacity: 1;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

#Group_211 {
    width: 20.505%;
    height: 31.915%;
    opacity: 1;
    position: relative;
    padding: 2%;
}

.submit-button-bulk {
    margin-top: 11%;
    left: 32%;
    width: 24.7386%;
    height: 10.5022%;
    background: #4169E1 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #4169E1;
    border-radius: 14px;
    opacity: 1;
    text-align: center;
    font: normal normal 600 calc(14*var(--responsiveFont))/calc(18*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    position: relative;
}

.submit-button-bulk:hover {
    background: #38814D 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #38814D;
}

.submit-button-bulk-confirm {
    margin-top: 1%;
    left: 39.6491%;
    width: 11.9558%;
    height: 7.4263%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #4169E1;
    border-radius: 14px;
    opacity: 1;
    text-align: center;
    font: normal normal 600 calc(14*var(--responsiveFont))/calc(18*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #1D1D1D;
    opacity: 1;
    position: relative;
}

.submit-button-bulk-confirm:hover {
    background: #4169E1 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #4169E1;
    color:#ffffff
}

.template-button-bulk {
    margin-top: 11%;
    left: 27%;
    width: 28.7386%;
    height: 10.5022%;
    background: #0D1265 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #0D1265;
    border-radius: 14px;
    opacity: 1;
    text-align: center;
    font: normal normal 600 calc(13*var(--responsiveFont))/calc(18*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #FCFCFC;
    opacity: 1;
    position: relative;
}

.template-button-bulk:hover {
    box-shadow: 0px 3px 6px #00000029;
    background: #141B8D 0% 0% no-repeat padding-box;
    border: 2px solid #141B8D;
    color: #FFFFFF;
}

.downloadIcon {
    margin-right: 10%;
    fill: #FCFCFC;
    height: 25%;
    width: auto;
}

.downloadIcon:hover {
    fill: #FFFFFF;
}

.upload-confirmation-IOI-summary-header{
    width: 100%;
    height: 6.8333%;
    display: flex;
    align-items: center;
    background: #FCFCFC 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #E3E3E3;
    opacity: 1;
}

.upload-confirmation-IOI-summary-header .bulk-upload-checkbox{
    background: #0D1265 0% 0% no-repeat padding-box;
    border: 1px solid #2C34B1;
}

.upload-confirmation-IOI-summary-header .bulk-upload-checkbox-check{
    border-color:#FFFFFF;
    width: calc(2*var(--responsiveFont));
    height: calc(6*var(--responsiveFont));
    border-width: 0 calc(2*var(--responsiveFont)) calc(2*var(--responsiveFont)) 0;
}

.bulk-upload-checkbox-container{
    width: 6.7382%;
    height: 39.0244%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bulk-upload-checkbox {
    height: 100%;
    width: 22%;
    border-radius: 50%;
    border: 1px solid #B1B1B1;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bulk-upload-checkbox-check {
    width: calc(3*var(--responsiveFont));
    height: calc(7*var(--responsiveFont));
    border: solid #0D1265;
    border-width: 0 calc(3*var(--responsiveFont)) calc(3*var(--responsiveFont)) 0;
    transform: rotate(45deg);
    position: relative;
}

.bulk-upload-checkbox-x {
    width: auto;
    height: 127%;
    overflow: visible;
    text-align: center;
    font: normal normal bold calc(14*var(--responsiveFont)) NotoSans;
    fill: #B50000;
    stroke: #B50000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-confirmation-summary-header-text{
    text-align: left;
    font: normal normal bold calc(13*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
    opacity: 1;
}

.bulk-upload-IOI-summary {
    width: 100%;
    height: 61.5%;
    -ms-overflow-style: none;  /* IE and Edge */
    overflow: overlay;
}

.bulk-upload-IOI-summary-entry {
    height: 12%;
    display: flex;
    align-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E6E6E6;
}

.bulk-upload-IOI-summary-entry-text {
    font: normal normal bold calc(14*var(--responsiveFont))/calc(20*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #000000;
    width: 93%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.bulk-upload-IOI-summary .bulk-upload-IOI-summary-entry:nth-child(even) {
    background-color: #F4F4F4;
}

.bulk-upload-IOI-summary-results {
    width: 100%;
    height: 6.8333%;
    display: flex;
    align-items: center;
    background: #FCFCFC 0% 0% no-repeat padding-box;
    opacity: 1;
    text-align: left;
    font: normal normal normal calc(12*var(--responsiveFont))/calc(10*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #000000;
    white-space: pre-wrap;
}

.bulk-file-drop-input {
    top: 19%;
    left: 6%;
    z-index: 11;
    width: 87.752%;
    height: 60.8942%;
    position: absolute;
    color: transparent;
    cursor: pointer;
}

.bulk-file-drop-input::file-selector-button {
    display: none;
}