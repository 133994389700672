.IOI-basic-popup {
    width: 33.7%;
    height: 41.6%;
    background: #FCFCFC 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #E3E3E3;
    border-radius: 29px;
    opacity: 1;
    z-index: 100;
    position: absolute;
    left: 33.76%;
    top: 29.2%;
}

.IOI-basic-popup-advanced {
    width: 33.7%;
    height: 41.6%;
    background: #FCFCFC 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #E3E3E3;
    border-radius: 29px;
    opacity: 1;
    padding-bottom: 1%;
    z-index: 100;
    position: absolute;
    left: 33.76%;
    top: 29.2%;
}

.IOI-review-top {
    width: 100%;
    height: 23.5%;
    background: #0D1265 0% 0% no-repeat padding-box;
    border-radius: 29px 29px 0px 0px;
    opacity: 1;
    text-align: center;
    font: normal normal bold calc(22*var(--responsiveFont))/calc(20*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #FCFCFC;
    opacity: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.IOI-connection-declined-top {
    width: 100%;
    height: 23.5%;
    background: #5D0505 0% 0% no-repeat padding-box;
    border-radius: 29px 29px 0px 0px;
    opacity: 1;
    text-align: center;
    font: normal normal bold calc(22*var(--responsiveFont))/calc(20*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #FCFCFC;
    opacity: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.IOI-review-body {
    width: 100%;
    height: 76.5%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.IOI-main-details {
    height: 31.6%;
    width: 92%;
    text-align: center;
    font: normal normal bold calc(23*var(--responsiveFont))/calc(25*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
}

.IOI-popup-main-details {
    width: 92%;
    height: auto;
    text-align: center;
    font: normal normal bold calc(23*var(--responsiveFont))/calc(25*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
}

.IOI-main-details-group-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.IOI-main-details-advanced {
    height: 31.6%;
    width: 83.7%;
    text-align: center;
    margin-top: 5%;
}

.IOI-main-details-security{
    text-align: center;
    font: normal normal bold calc(23*var(--responsiveFont))/calc(25*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
}

.IOI-main-details-direction-buy {
    text-align: center;
    font: normal normal bold calc(23*var(--responsiveFont))/calc(25*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0EAA3B;
}

.IOI-main-details-direction-sell {
    text-align: center;
    font: normal normal bold calc(23*var(--responsiveFont))/calc(25*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #BC3C3C;
}

.IOI-main-details-amount {
    text-align: center;
    font: normal normal 500 calc(20*var(--responsiveFont))/calc(25*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #2F2F2F;
}

.IOI-advanced-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-bottom: 5%;
    margin-top: 1%;
}

.hide {
    display: none;
}

.IOI-advanced-details-label {
    text-align: left;
    font: normal normal 500 calc(16*var(--responsiveFont))/calc(25*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #2F2F2F;
    opacity: 1;
    width: 50%;
}

.IOI-advanced-details-value-buy {
    text-align: center;
    font: normal normal bold calc(16*var(--responsiveFont))/calc(25*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0EAA3B;
    opacity: 1;
    width: 30%;
}

.IOI-advanced-details-value-sell {
    text-align: center;
    font: normal normal bold calc(16*var(--responsiveFont))/calc(25*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #BC3C3C;
    opacity: 1;
    width: 30%;
}

.IOI-advanced-details-content-div {
    width: 100%;
    display: flex;
    justify-content: center;
}

.IOI-review-buttons {
    height: 25%;
    display: flex;
    width: 83%;
    justify-content: space-evenly;
    align-items: center;
}

.review-cancel-button {
    width: 30%;
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 3px solid #C64B8C;
    border-radius: 14px;
    opacity: 1;
    text-align: center;
    font: normal normal 600 calc(14*var(--responsiveFont))/calc(18*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #1D1D1D;
}

.review-cancel-button:hover {
    background: #C64B8C;
    color: #FFFFFF;
}

.review-send-button {
    width: 30%;
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #4169E1;
    border-radius: 14px;
    opacity: 1;
    text-align: center;
    font: normal normal 600 calc(14*var(--responsiveFont))/calc(18*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #1D1D1D;
}

.review-send-button:hover {
    background: #4169E1 0% 0% no-repeat padding-box;
    border: 2px solid #4169E1;
    color: #FFFFFF;
}

.connection-declined-button {
    width: 30%;
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #7D1B24;
    border-radius: 14px;
    opacity: 1;
    text-align: center;
    font: normal normal 600 calc(14*var(--responsiveFont))/calc(18*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #1D1D1D;
}

.connection-declined-button:hover {
    background: #982D39 0% 0% no-repeat padding-box;
    border: 2px solid #982D38;
    color: #FFFFFF;
}


.IOI-connection-accepted-popup {
    width: 36.1%;
    height: 91.5%;
    background: #FCFCFC 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #E3E3E3;
    border-radius: 29px;
    opacity: 1;
    z-index: 100;
    position: absolute;
    left: 33.76%;
    top: 4.25%;
}

.IOI-connection-accepted-top {
    height: 10.66%;
    width: 100%;
    background: #4169E1 0% 0% no-repeat padding-box;
    border-radius: 29px 29px 0px 0px;
    opacity: 1;
    text-align: center;
    font: normal normal bold calc(22*var(--responsiveFont))/calc(20*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #FCFCFC;
    display: flex;
    align-items: center;
    justify-content: center;
}

.IOI-connection-accepted-body {
    width: 100%;
    height: 89.3%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.IOI-accepted-security {
    text-align: center;
    font: normal normal bold calc(26*var(--responsiveFont))/calc(27*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
    opacity: 1;
    height: 7.5%;
    width: 78.1%;
    margin-top: 2.45%;
    margin-bottom: 4.75%;
}

.IOI-accepted-security-locate {
    text-align: center;
    font: normal normal bold calc(26*var(--responsiveFont))/calc(27*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
    opacity: 1;
    min-height: 7.5%;
    width: 78.1%;
    margin-top: 2.45%;
    margin-bottom: 4.75%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.IOI-connection-details {
    height: 33.58%;
    width: 93.01%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #C1C0C0;
}

.IOI-connection-broker-details {
    height: 11.05%;
    width: 93.01%;
    display: flex;
    flex-direction: column;
    margin-top: 3.145%;
    margin-bottom: 5.667%;
}

.IOI-connection-detail-div {
    width: 100%;
    height: 50%;
    display: flex;
    margin-bottom: 2.11%;
}

.IOI-connection-detail-label {
    text-align: left;
    font: normal normal 500 calc(16*var(--responsiveFont))/calc(25*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #2F2F2F;
    opacity: 1;
    margin-left: 10.3%;
    width: 36.11%;
}

.IOI-contra-located-detail-value-buy {
    text-align: center;
    font: normal normal bold calc(16*var(--responsiveFont))/calc(25*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0EAA3B;
    opacity: 1;
    width: 35%;
}

.IOI-contra-located-detail-value-sell {
    text-align: center;
    font: normal normal bold calc(16*var(--responsiveFont))/calc(25*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #BC3C3C;
    opacity: 1;
    width: 53.59%;
}

.IOI-connection-detail-value-buy {
    text-align: center;
    font: normal normal bold calc(16*var(--responsiveFont))/calc(25*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0EAA3B;
    opacity: 1;
    width: 53.59%;
}

.IOI-connection-detail-value-sell {
    text-align: center;
    font: normal normal bold calc(16*var(--responsiveFont))/calc(25*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #BC3C3C;
    opacity: 1;
    width: 53.59%;
}

.IOI-connection-detail-value {
    text-align: center;
    font: normal normal 500 calc(16*var(--responsiveFont))/calc(25*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
    opacity: 1;
    width: 53.59%;
}

.IOI-connection-buttons {
    width: 78.565%;
    height:9.176%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5.13%;
}

.connection-copy-details-button {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    width: 42.76%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #0D1265;
    opacity: 1;
    text-align: left;
    font: normal normal 600 calc(14*var(--responsiveFont))/calc(30*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
    border-radius: 22px;
}

.connection-copy-details-button:hover {
    background: #0D1265 0% 0% no-repeat padding-box;
    color: #FFFFFF;
}

.connection-copy-details-button:hover #Group_38_hh {
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(73deg) brightness(102%) contrast(102%);
}

#Group_38_hh {
    width: 9.09%;
    height: 37.68%;
}

.connection-residual-amount-button {
    width: 51.09%;
    height: 100%;
    text-align: center;
    font: normal normal 600 calc(14*var(--responsiveFont))/calc(30*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #0D1265;
    opacity: 1;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #0D1265;
    border-radius: 22px;
}

.connection-residual-amount-button:hover {
    background: #0D1265 0% 0% no-repeat padding-box;
    color: #FFFFFF;
}

.IOI-connection-close-button {
    width: 23.22%;
    height: 9.176%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #4169E1;
    border-radius: 14px;
    text-align: center;
    font: normal normal 600 calc(14*var(--responsiveFont))/calc(18*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #1D1D1D;
    margin-bottom: 2%;
}

.IOI-connection-close-button:hover {
    background: #4169E1 0% 0% no-repeat padding-box;
    border: 2px solid #4169E1;
    color: #FFFFFF;
}

.IOI-locate-close-button {
    width: 23.22%;
    height: 12%;
    background: #0D1265 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #0D1265;
    border-radius: 14px;
    text-align: center;
    font: normal normal 600 calc(14*var(--responsiveFont))/calc(18*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #FCFCFC;
    margin-bottom: 2%;
}

.IOI-locate-close-button:hover {
    background: #141B8D 0% 0% no-repeat padding-box;
    border: 2px solid #141B8D;
    color: #FFFFFF;
}

.match-confirmed-close-button {
    width: 23.22%;
    height: 18%;
    background: #0D1265 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #0D1265;
    border-radius: 14px;
    text-align: center;
    font: normal normal 600 calc(14*var(--responsiveFont))/calc(18*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #FCFCFC;
}

.match-confirmed-close-button:hover {
    background: #141B8D 0% 0% no-repeat padding-box;
    border: 2px solid #141B8D;
    color: #FFFFFF;
}

.IOI-contra-located-popup {
    width: 32.48%;
    height: 75%;
    background: #FCFCFC 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #E3E3E3;
    border-radius: 29px;
    z-index: 100;
    position: absolute;
    left: 33.76%;
    top: 17.5%;
}

.IOI-contra-located-top {
    width: 100%;
    height: 15.7%;
    text-align: center;
    font: normal normal bold calc(22*var(--responsiveFont))/calc(20*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #000000;
    background: #C9C9C9 0% 0% no-repeat padding-box;
    border-radius: 22px 22px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.IOI-contra-located-body {
    width: 100%;
    height: 84.3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.match-confirmed-body {
    width: 100%;
    height: 79.3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.IOI-contra-located-details {
    height: 66%;
    width: 72%;
    display: flex;
    flex-direction: column;
}

.IOI-contra-detail-label {
    text-align: left;
    font: normal normal 500 calc(16*var(--responsiveFont))/calc(25*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #2F2F2F;
    opacity: 1;
    width: 65%;
}

.IOI-contra-located-buttons {
    height: 14.52%;
    width: 65.81%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3%;
}

.match-confirmed-buttons {
    height: 18.52%;
    width: 65.81%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contra-decline-button {
    width: 39.25%;
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #C64B8C;
    border-radius: 14px;
    text-align: center;
    font: normal normal 600 calc(14*var(--responsiveFont))/calc(18*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #1D1D1D;
}

.contra-decline-button:hover {
    background: #C64B8C 0% 0% no-repeat padding-box;
    border: 2px solid #C64B8C;
    color: #FFFFFF;
}

.contra-located-timer {
    height: 81.36%;
    width: 13.35%;
    text-align: center;
    font: normal normal bold calc(18*var(--responsiveFont))/calc(20*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #FFFFFF;
    background: #0D1265 0% 0% no-repeat padding-box;
    border: 1px solid #A7A7A7;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contra-accept-button {
    height: 100%;
    width: 39.25%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #4169E1;
    border-radius: 14px;
    text-align: center;
    font: normal normal 600 calc(14*var(--responsiveFont))/calc(18*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #1D1D1D;
}

.contra-accept-button:hover{
    background: #4169E1 0% 0% no-repeat padding-box;
    border: 2px solid #4169E1;
    color: #FFFFFF;
}

#Group_38_hh_password:hover {
    height: 55%;
}

#Group_38_hh_password {
    width: auto;
    height: 70%;
    margin-left: 3%;
}

.IOI-connection-divider-div {
    height: 1px;
    width: 100%;
    background-color: #C1C0C0;
    border-bottom: 1px solid #C1C0C0;
    margin-bottom: 2%;
}