.stock-selector-main {
    position: relative;
    align-items: center;
    height: 125.5px;
    width: 88.125%;
    background-color: rgba(255,255,255,1);
    color: rgba(77,79,92,1);
	margin: auto;
	border-bottom: 1px solid #D8D8D8;
	border: 1px solid #D8D8D8;
	border-radius: 0px 0px 29px 29px;
    display: flex;
  }

.stock-search-bar {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #0D1265;
    border-radius: 22px;
    opacity: 1;
    width: 66.408%;
    height: 21.359%;
    text-align: left;
    font: normal normal 500 calc(14*var(--responsiveFont))/calc(13*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #96969F;
    opacity: 1;
    display: flex;
    left: 3.152%;
    top: 57.767%;
    position: relative;
    -webkit-transition: width 1s, height 1s, left 1s, top 1s; /* Safari */
    transition: width 1s, height 1s, left 1s, top 1s;
}

.stock-search-bar.basic {
    height: 7.496%;
    top: 20.194%;
    width: 44.526%;
    left: 26.005%;
    position: absolute;
}

.stock-search-bar.advanced {
    height: 6.154%;
    top: 16.643%;
    width: 44.526%;
    left: 26.005%;
    position: absolute;
}

#searchForm {
    border: none;
}

#searchInput {
    border: none;
    width: 100%;
    text-decoration: none;
    text-align: left;
    font-family: NotoSans;
    font-weight: bold;
    font-size: calc(14*var(--responsiveFont));
    line-height: calc(13*var(--responsiveFont));
    letter-spacing: 0px;
    color: #0D1265;
}

#searchInput:focus{
    outline: none;
}

#searchInput::placeholder{
    font-weight: 500;
}

.stock-search-bar-dropdown {
    background: #FCFCFC 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #CBCBCB;
    border-radius: 9px;
    opacity: 1;
    display: none;
    width: 67%;
    margin-right: auto;
    margin-left: 3%;
    margin-top: 6.5%;
    flex-direction: column;
	position: absolute;
	justify-content: left;
	justify-items: center;
	z-index: 10;
}

.stock-search-bar-dropdown span {
    display: block;
    position:relative;
    width: 100%;
    margin-left: 2.6%;
    margin-bottom: 1.201%;
}

.stock-search-bar-dropdown > :first-child {
    margin-top: 1.651%;
}

.stock-search-bar-dropdown > :last-child {
    margin-bottom: 1.651%;
}

.stock-search-bar-dropdown span:hover {
    font-weight: bold;
    color: #0D1265;
    cursor: pointer;
}

#Start_by_typing_a_stock_to_adv_sq {
    left: 2.513%;
    overflow: visible;
    width: 90%;
    white-space: nowrap;
    text-align: left;
    font-family: NotoSans;
    font-style: normal;
    font-weight: normal;
    font-size: calc(14*var(--responsiveFont));
    color: rgba(150,150,159,1);
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
}

#Start_by_typing_a_stock_to_adv_sq:focus ~ .stock-search-bar-dropdown{
    display: block;
}

.bulk-upload {
    background: #0D1265 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 34px;
    opacity: 1;
    width: 12.136%;
    height: 21.359%;
    position: absolute;
    left: 71.712%;
    top: 57.544%;
    display: flex;
    cursor: pointer;
    -webkit-transition: width 1s, height 1s, top 1s, left 1s; /* Safari */
    transition: width 1s, height 1s, top 1s, left 1s;
    align-items: center;
}

.bulk-upload.basic {
    height: 7.496%;
    top: 20.194%;
}

.bulk-upload.advanced {
    height: 6.154%;
    top: 16.643%;
    position: absolute;
}

.bulk-upload:hover {
    background-color: #141B8D;
}

.bulk-upload-text {
    text-align: center;
    font: normal normal normal calc(14*var(--responsiveFont))/calc(30*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    position: relative;
    width: 62%;
    height: 100%;
    margin-left: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.restore-ioi {
    background: #0D1265 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 34px;
    opacity: 1;
    width: 12.136%;
    height: 21.359%;
    position: absolute;
    left: 84.712%;
    top: 57.544%;
    display: flex;
    cursor: pointer;
    -webkit-transition: width 1s, height 1s, top 1s, left 1s; /* Safari */
    transition: width 1s, height 1s, top 1s, left 1s;
    align-items: center;
}

.restore-ioi:hover {
    background-color: #141B8D;
}

.restore-ioi.disabled {
    background: #54555b 0% 0% no-repeat padding-box;
}

.restore-ioi.basic {
    height: 7.496%;
    top: 20.194%;
}

.restore-ioi.advanced {
    height: 6.154%;
    top: 16.643%;
    position: absolute;
}

.restore-ioi-text {
    text-align: center;
    font: normal normal normal calc(14*var(--responsiveFont))/calc(30*var(--responsiveFont)) NotoSans;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    position: relative;
    width: 82%;
    height: 100%;
    margin-left: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#icon_search_sr {
    position: relative;
    width: 1.609%;
    height: 36.4%;
    left: 1.81%;
    margin-top: auto;
    margin-bottom: auto;
    overflow: visible;
}

.icon_search_ss {
    overflow: visible;
    display: block;
    width: 100%;
    height: 100%;
    transform: matrix(1,0,0,1,0,0);
}

#icon_search_ {
    fill: rgba(13,18,101,1);
}

#Component_42__2_n {
    width: 2.595%;
    height: 40%;
    overflow: visible;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

#Component_42__2_n:hover {
    cursor: pointer;
}

#Component_42__2_n:hover #search_arrow {
    fill: #1921AA;
}

#search_arrow {
    fill: #0D1265;
}

#search_arrow_container {
    height: 100%;
    width: 100%;
}

#Group_32 {
    width: 9.095%;
    height: 38.65%;
    position: relative;
    margin-left: 15%;
}